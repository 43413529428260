// src/pages/ForgotPassword.js

import React, { useState } from "react";
import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // メールアドレスのバリデーション関数
  const validateEmail = (email) => {
    // シンプルなメールアドレスの正規表現
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // クライアントサイドのバリデーション
    if (!validateEmail(email)) {
      setError("有効なメールアドレスを入力してください。");
      return;
    }

    try {
      setIsSubmitting(true);
      setError("");
      setMessage("");
      const response = await axios.post("/forgot-password", { email });
      setMessage(
        response.data.message ||
          "パスワードリセットの手順をメールで送信しました。"
      );
    } catch (error) {
      console.error("Error requesting password reset:", error);
      setError(
        error.response?.data?.error ||
          "パスワードリセットのリクエストに失敗しました。"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          パスワードをお忘れですか？
        </h2>
        {message && (
          <p className="text-green-500 mb-4 text-center font-semibold">
            {message}
          </p>
        )}
        {error && (
          <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
        )}
        <form onSubmit={handleSubmit} noValidate>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 dark:text-gray-300 mb-2"
            >
              登録したメールアドレス
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                error ? "border-red-500" : "border-gray-300"
              } bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white`}
              placeholder="メールアドレスを入力"
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-dark transition-colors duration-200 ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? "送信中..." : "パスワードリセットメールを送信"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
