// frontend/src/components/SubscriptionModal.js
import React from "react";
import { Transition, Dialog } from "@headlessui/react";

const SubscriptionModal = ({
  isSubscriptionModalOpen,
  setIsSubscriptionModalOpen,
  isPremium,
  handlePurchase,
  handleCancelSubscription,
}) => {
  // フリープランとプレミアムプランのモデルリスト
  const freePlanModels = ["Claude 3.5 Haiku", "GPT-4o", "Gemini 1.5 Flash"];

  const premiumPlanModels = [
    "Claude 3.5 Haiku",
    "GPT-4o",
    "Gemini 1.5 Flash",
    "Gemeni 1.5 Pro",
    "Claude 3.5 sonnet",
    "Perplexity",
    "Stable Image Ultra",
    "DALL-E 3",
    "o1 preview",
  ];

  return (
    <Transition appear show={isSubscriptionModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsSubscriptionModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold leading-6 text-gray-900 dark:text-white text-center"
                >
                  プランの選択
                </Dialog.Title>
                <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* フリープラン */}
                  <div className="flex flex-col bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600">
                    <h4 className="text-xl font-semibold text-gray-900 dark:text-white text-center mb-4">
                      フリー
                    </h4>
                    <div className="flex-1">
                      <ul className="space-y-3">
                        <li className="text-gray-700 dark:text-gray-200 font-bold">
                          利用可能なAIモデル
                        </li>
                        {freePlanModels.map((model) => (
                          <li className="flex items-center" key={model}>
                            <span className="text-green-500">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </span>
                            <span className="ml-3 text-gray-700 dark:text-gray-200">
                              {model}
                            </span>
                          </li>
                        ))}
                        {premiumPlanModels
                          .filter((model) => !freePlanModels.includes(model))
                          .map((model) => (
                            <li className="flex items-center" key={model}>
                              <span className="text-red-500">
                                <svg
                                  className="w-6 h-6"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </span>
                              <span className="ml-3 text-gray-500 dark:text-gray-400">
                                {model}
                              </span>
                            </li>
                          ))}
                        <li className="text-gray-700 dark:text-gray-200 font-bold mt-4">
                          やったことリストの作成
                        </li>
                        <li className="flex items-center">
                          <span className="text-green-500">
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </span>
                          <span className="ml-3 text-gray-700 dark:text-gray-200">
                            今日やったこと
                          </span>
                        </li>
                        <li className="flex items-center">
                          <span className="text-red-500">
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </span>
                          <span className="ml-3 text-gray-500 dark:text-gray-400">
                            今週やったこと
                          </span>
                        </li>
                        <li className="flex items-center">
                          <span className="text-red-500">
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </span>
                          <span className="ml-3 text-gray-500 dark:text-gray-400">
                            今月やったこと
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-6">
                      <p className="text-2xl font-bold text-gray-900 dark:text-white text-center">
                        無料
                      </p>
                    </div>
                    {!isPremium ? (
                      <div className="mt-4 text-center">
                        <span className="text-green-600 font-semibold">
                          現在のプラン
                        </span>
                      </div>
                    ) : (
                      <div className="mt-4 flex justify-center">
                        <button
                          onClick={handleCancelSubscription}
                          className="px-4 py-2 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200"
                        >
                          プラン変更
                        </button>
                      </div>
                    )}
                  </div>
                  {/* プレミアムプラン */}
                  <div className="flex flex-col bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg border border-blue-500">
                    <h4 className="text-xl font-semibold text-gray-900 dark:text-white text-center mb-4">
                      プレミアムプラン
                    </h4>
                    <div className="flex-1">
                      <ul className="space-y-3">
                        <li className="text-gray-700 dark:text-gray-200 font-bold">
                          利用可能なAIモデル
                        </li>
                        {premiumPlanModels.map((model) => (
                          <li className="flex items-center" key={model}>
                            <span className="text-green-500">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </span>
                            <span className="ml-3 text-gray-700 dark:text-gray-200">
                              {model}
                            </span>
                          </li>
                        ))}
                        <li className="text-gray-700 dark:text-gray-200 font-bold mt-4">
                          やったことリストの作成
                        </li>
                        <li className="flex items-center">
                          <span className="text-green-500">
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </span>
                          <span className="ml-3 text-gray-700 dark:text-gray-200">
                            今日やったこと
                          </span>
                        </li>
                        <li className="flex items-center">
                          <span className="text-green-500">
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </span>
                          <span className="ml-3 text-gray-700 dark:text-gray-200">
                            今週やったこと
                          </span>
                        </li>
                        <li className="flex items-center">
                          <span className="text-green-500">
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </span>
                          <span className="ml-3 text-gray-700 dark:text-gray-200">
                            今月やったこと
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-6">
                      <p className="text-2xl font-bold text-gray-900 dark:text-white text-center">
                        月額3000円
                      </p>
                    </div>
                    {isPremium ? (
                      <div className="mt-4 text-center">
                        <span className="text-green-600 font-semibold">
                          現在のプラン
                        </span>
                      </div>
                    ) : (
                      <div className="mt-4 flex justify-center">
                        <button
                          onClick={handlePurchase}
                          className="px-4 py-2 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200"
                        >
                          プラン変更
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-4 flex justify-center">
                  <button
                    onClick={() => setIsSubscriptionModalOpen(false)}
                    className="text-gray-500 hover:underline"
                  >
                    閉じる
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SubscriptionModal;
