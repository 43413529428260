// frontend/src/components/common/TaskEditor.js
import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import { Bold, Italic, List, Heading as HeadingIcon } from "lucide-react";

// カスタムBulletList拡張機能の作成
const CustomBulletList = BulletList.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      HTMLAttributes: {
        class: "custom-bullet-list",
      },
    };
  },
});

// カスタムListItem拡張機能の作成
const CustomListItem = ListItem.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      HTMLAttributes: {
        class: "custom-list-item",
      },
    };
  },
});

const TaskEditor = ({ content, onChange }) => {
  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph.configure({
          HTMLAttributes: {
            class: "mb-1",
          },
        }),
        Text,
        CustomBulletList.configure({
          keepMarks: true,
          keepAttributes: true,
        }),
        CustomListItem,
        StarterKit.configure({
          bulletList: false,
          heading: {
            levels: [1, 2, 3],
          },
          // 以下の拡張機能を無効化
          document: false,
          paragraph: false,
          text: false,
          listItem: false,
        }),
      ],
      content: content,
      onUpdate: ({ editor }) => {
        onChange(editor.getHTML());
      },
      editorProps: {
        attributes: {
          class:
            "prose prose-sm dark:prose-invert focus:outline-none w-full min-h-[200px] px-3 py-0 task-editor",
        },
      },
    },
    []
  );

  useEffect(() => {
    if (editor && editor.getHTML() !== content) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="border border-gray-300 dark:border-gray-600 rounded-md">
      {/* ツールバー */}
      <div className="flex items-center gap-2 p-2 border-b border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={`p-1 rounded ${
            editor.isActive("bold")
              ? "bg-gray-200 dark:bg-gray-600"
              : "hover:bg-gray-100 dark:hover:bg-gray-700"
          }`}
        >
          <Bold className="w-4 h-4" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={`p-1 rounded ${
            editor.isActive("italic")
              ? "bg-gray-200 dark:bg-gray-600"
              : "hover:bg-gray-100 dark:hover:bg-gray-700"
          }`}
        >
          <Italic className="w-4 h-4" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`p-1 rounded ${
            editor.isActive("bulletList")
              ? "bg-gray-200 dark:bg-gray-600"
              : "hover:bg-gray-100 dark:hover:bg-gray-700"
          }`}
        >
          <List className="w-4 h-4" />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={`p-1 rounded ${
            editor.isActive("heading", { level: 2 })
              ? "bg-gray-200 dark:bg-gray-600"
              : "hover:bg-gray-100 dark:hover:bg-gray-700"
          }`}
        >
          <HeadingIcon className="w-4 h-4" />
        </button>
      </div>

      {/* エディター本体 */}
      <style>
        {`
          .task-editor .custom-bullet-list {
            padding-left: 1rem;
            margin-top: 0.25rem !important;
            margin-bottom: 0.25rem !important;
          }
          
          .task-editor .custom-list-item {
            margin-top: 0.25rem !important;
            margin-bottom: 0.25rem !important;
          }
          
          .task-editor .custom-list-item p {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
          
          .task-editor p {
            margin-top: 0.5rem !important;
            margin-bottom: 0.5rem !important;
          }
          
          .task-editor ul {
            margin-top: 0.5rem !important;
            margin-bottom: 0.5rem !important;
          }
          
          /* Prosemirrorのデフォルトスタイルをオーバーライド */
          .ProseMirror .custom-bullet-list {
            padding-left: 1rem;
          }
          
          .ProseMirror .custom-list-item {
            margin: 0.25rem 0;
          }
        `}
      </style>
      <EditorContent editor={editor} className="prose-editor-container" />
    </div>
  );
};

export default TaskEditor;
