// PrivacyPolicy.js
import React from "react";

function PrivacyPolicy() {
  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">プライバシーポリシー</h1>
      <p className="mb-4">最終更新日：2023年8月15日</p>

      <p className="mb-4">
        私たち、Chat
        Tree（以下「当社」または「私たち」）は、お客様のプライバシーを尊重し、お客様から収集した情報を保護することに強く取り組んでいます。本プライバシーポリシーでは、ウェブサイト、アプリケーション、およびサービス（以下「サービス」）の利用時に収集される個人情報に関する当社の取り組みを説明します。本プライバシーポリシーは、当社のAPIなどのビジネス提供の顧客のために処理するコンテンツには適用されません。そのデータの使用は、当該提供へのアクセスおよび使用をカバーする顧客契約によって規定されます。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">1. 収集する個人情報</h2>
      <p className="mb-4">
        当社は、以下の方法でお客様に関連する個人情報（以下「個人情報」）を収集します。
      </p>
      <p className="mb-2 font-semibold">提供いただく個人情報:</p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>アカウント情報:</strong>{" "}
          サービスを利用するためにアカウントを作成する際、お名前、連絡先情報、アカウントの資格情報、支払い情報、取引履歴などの情報を収集します。
        </li>
        <li>
          <strong>ユーザーコンテンツ:</strong>{" "}
          サービスを使用する際に、入力、ファイルのアップロード、またはフィードバックに含まれる個人情報を収集します。
        </li>
        <li>
          <strong>コミュニケーション情報:</strong>{" "}
          当社とコミュニケーションを取る場合、お名前、連絡先情報、送信されたメッセージの内容などを収集します。
        </li>
        <li>
          <strong>ソーシャルメディア情報:</strong>{" "}
          当社はInstagram、Facebook、Twitter、YouTube、LinkedInなどのソーシャルメディアサイトにページを持っています。これらのページとのやり取り時に、お名前や連絡先情報などの個人情報を提供いただく場合があります。
        </li>
      </ul>

      <p className="mb-2 font-semibold">
        サービスの利用に関して自動的に受信する個人情報:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>ログデータ:</strong>{" "}
          サービスを利用する際にブラウザが自動的に送信する情報です。IPアドレス、ブラウザの種類と設定、リクエストの日時、ウェブサイトとのインタラクション方法が含まれます。
        </li>
        <li>
          <strong>利用データ:</strong>{" "}
          サービスの利用に関する情報（閲覧したコンテンツ、使用した機能、実行したアクションなど）を自動的に収集します。
        </li>
        <li>
          <strong>デバイス情報:</strong>{" "}
          デバイスの名前、オペレーティングシステム、デバイス識別子、使用しているブラウザなどを収集します。
        </li>
        <li>
          <strong>クッキー:</strong>{" "}
          サービスの運営と管理、および利用体験の向上のためにクッキーを使用します。詳細については、「All
          About Cookies」を参照してください。
        </li>
        <li>
          <strong>アナリティクス:</strong>{" "}
          サービスの利用方法を分析し、利用体験を向上させるためにオンラインアナリティクス製品を使用することがあります。
        </li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">2. 個人情報の利用方法</h2>
      <p className="mb-4">
        当社は、以下の目的で個人情報を使用することがあります。
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>サービスの提供、管理、維持、および分析</li>
        <li>サービスの改善および調査</li>
        <li>お客様とのコミュニケーション</li>
        <li>新しいプログラムやサービスの開発</li>
        <li>
          不正行為、犯罪行為、またはサービスの悪用の防止、およびITシステム、アーキテクチャ、ネットワークのセキュリティの保護
        </li>
        <li>ビジネスの移転の実行</li>
        <li>
          法的義務と法的手続きの遵守、および当社、提携先、お客様、その他の第三者の権利、プライバシー、安全性、財産を保護するため
        </li>
      </ul>
      <p className="mb-4">
        <strong>集計または匿名化された情報:</strong>{" "}
        個人を特定できないように個人情報を集計または匿名化することがあります。その情報をサービスの効果を分析したり、機能を追加したりするために使用します。また、一般的なユーザー統計情報などを第三者と共有したり、公開したりすることがあります。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">3. 個人情報の開示</h2>
      <p className="mb-4">
        特定の状況において、法律による要件がある場合を除き、事前の通知なしに個人情報を第三者に提供することがあります。
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>ベンダーやサービスプロバイダー:</strong>{" "}
          ビジネス運用のニーズを満たすために、ホスティングサービス、クラウドサービス、メールコミュニケーションソフトウェア、ウェブアナリティクスサービスなどのベンダーやサービスプロバイダーに個人情報を提供する場合があります。
        </li>
        <li>
          <strong>事業転換:</strong>{" "}
          戦略的トランザクション、再編成、破産、または他のプロバイダーへのサービス移行に関与する場合、個人情報が移転される場合があります。
        </li>
        <li>
          <strong>法的要件:</strong>{" "}
          政府機関、業界仲間、または他の第三者と個人情報を共有する場合があります。これは、法律で必要とされる場合や、当社の権利や財産を保護するためなどです。
        </li>
        <li>
          <strong>提携先:</strong>{" "}
          個人情報をコントロールするエンティティである提携先に対して個人情報を開示する場合があります。
        </li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">4. お客様の権利</h2>
      <p className="mb-4">
        お客様は、個人情報に関する特定の法定権利を有する場合があります。例えば、以下の権利があります。
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>個人情報とその処理方法に関する情報へのアクセス</li>
        <li>個人情報の削除</li>
        <li>個人情報の訂正または更新</li>
        <li>個人情報を第三者に転送する権利（データポータビリティ）</li>
        <li>個人情報の処理方法の制限</li>
        <li>同意の撤回（処理の法的根拠として同意を依存する場合）</li>
        <li>個人情報の処理方法に対する異議</li>
        <li>データ保護機関に苦情を申し立てる権利</li>
      </ul>
      <p className="mb-4">
        これらの権利の一部は、Chat
        Treeアカウントを通じて行使できる場合があります。アカウントを通じて権利を行使できない場合は、
        <a href="mailto:info@copelnix.com" className="underline">
          info@copelnix.com
        </a>{" "}
        までリクエストを送信してください。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">
        5. 他のウェブサイトへのリンク
      </h2>
      <p className="mb-4">
        サービスには、当社が運営または制御しない他のウェブサイトへのリンクが含まれている場合があります（以下、「第三者サイト」）。第三者サイトと共有する情報は、特定のプライバシーポリシーや利用規約によって規制され、本プライバシーポリシーによって規制されるものではありません。これらのリンクを提供することによって、当社がこれらのサイトを支持またはレビューしたことを示唆するものではありません。第三者サイトのプライバシー実践とポリシーについては、直接第三者サイトにお問い合わせください。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">6. セキュリティと保持</h2>
      <p className="mb-4">
        当社は、オンラインおよびオフラインの両方で、喪失、誤用、未承認のアクセス、開示、変更、または破壊から個人情報を保護するための合理的な技術的および組織的な対策を講じています。ただし、インターネットまたは電子メールの転送は完全に安全またはエラーフリーではありません。特に、当社に送信される電子メールは安全ではない可能性があります。サービスまたは電子メールを通じて当社に送信する情報を慎重に選択してください。また、サービスまたは第三者ウェブサイトに含まれるプライバシー設定またはセキュリティ対策の回避について、当社は責任を負いません。
      </p>
      <p className="mb-4">
        当社は、サービスの提供、紛争の解決、安全およびセキュリティの理由、法的義務の遵守など、合法的なビジネス目的に必要な期間のみ個人情報を保持します。個人情報を保持する期間は、情報の量、性質、感度、未承認の使用または開示による潜在的なリスク、情報の処理目的、法的要件など、さまざまな要因によって異なります。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">
        7. プライバシーポリシーの変更
      </h2>
      <p className="mb-4">
        当社は、定期的に本プライバシーポリシーを更新することがあります。更新されたバージョンはこのページに掲載します。ただし、適用法によって別の種類の通知が必要な場合を除き、その他の通知は提供されません。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">8. お問い合わせ方法</h2>
      <p className="mb-4">
        本プライバシーポリシーで対応されていない質問や懸念事項がある場合は、サポートまでお問い合わせください。
      </p>
      <p>
        電子メール：
        <a href="mailto:info@copelnix.com" className="underline">
          info@copelnix.com
        </a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
