// Layout.js

import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import SideMenu from "./SideMenu";
import Header from "./Header";
import useChat from "../../hooks/useChat";
import TaskList from "./TaskList";

function Layout({ handleLogout, email, theme, setTheme }) {
  const initialWindowWidth = window.innerWidth;
  // モバイルではサイドバー・タスクリストはデフォルト閉じる
  const [isTaskbarOpen, setIsTaskbarOpen] = useState(
    initialWindowWidth >= 1200
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    initialWindowWidth >= 1200
  );
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [aiModels, setAiModels] = useState([]);
  const chatContext = useChat();
  const [taskbarWidth, setTaskbarWidth] = useState(320);
  const [isResizing, setIsResizing] = useState(false);
  const sidebarRef = useRef(null);
  const [isPremium, setIsPremium] = useState(false);

  const {
    messages,
    pastChats,
    activeChatId,
    currentChatTitle,
    startNewChat,
    deleteChat,
    renameChat,
  } = chatContext;

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setIsSidebarOpen(false);
        setIsTaskbarOpen(false);
      } else {
        setIsSidebarOpen(true);
        setIsTaskbarOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    axios
      .get("/user/profile")
      .then((res) => {
        setTheme(res.data.theme || "system");
        if (res.data.profileImageUrl) {
          setProfileImageUrl(res.data.profileImageUrl);
        }
        if (res.data.aiModels) {
          setAiModels(res.data.aiModels);
        }
        if (res.data.isPremium !== undefined) {
          setIsPremium(res.data.isPremium);
        }
      })
      .catch((err) => {
        console.error("Error fetching user profile:", err);
      });
  }, [setTheme]);

  const handleMouseDown = (e) => {
    setIsResizing(true);
    e.preventDefault();
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isResizing) {
        const newWidth = window.innerWidth - e.clientX;
        if (newWidth >= 256) {
          setTaskbarWidth(newWidth);
        }
      }
    };

    const handleMouseUp = () => {
      if (isResizing) {
        setIsResizing(false);
      }
    };

    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-background dark:bg-background-dark">
      {/* ヘッダー */}
      <Header
        currentChatTitle={currentChatTitle}
        setIsSidebarOpen={setIsSidebarOpen}
        isSidebarOpen={isSidebarOpen}
        setIsTaskbarOpen={setIsTaskbarOpen}
        isTaskbarOpen={isTaskbarOpen}
      />
      {/* メインコンテンツラッパ */}
      <div className="flex-1 flex relative overflow-hidden">
        {/* メインコンテンツ */}
        <div
          className="flex-1 flex flex-col min-h-0 overflow-hidden"
          onClick={(e) => {
            if (window.innerWidth < 1200) {
              if (
                isSidebarOpen &&
                sidebarRef.current &&
                !sidebarRef.current.contains(e.target)
              ) {
                setIsSidebarOpen(false);
              }
              if (isTaskbarOpen) {
                setIsTaskbarOpen(false);
              }
            }
          }}
        >
          <Outlet context={{ ...chatContext, aiModels }} />
        </div>

        {/* サイドメニュー（左側） */}
        <div
          ref={sidebarRef}
          className={`
            fixed top-0 left-0 h-full w-64 bg-sidebarBackground dark:bg-sidebarBackground-dark 
            border-r border-gray-200 dark:border-gray-700 
            transform transition-transform duration-300 z-20
            ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}
          `}
        >
          <SideMenu
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            handleLogout={handleLogout}
            email={email}
            theme={theme}
            setTheme={setTheme}
            profileImageUrl={profileImageUrl}
            pastChats={pastChats}
            activeChatId={activeChatId}
            onChatSelect={(chatId) => {
              navigate(`/chat/${chatId}`);
            }}
            onChatDelete={deleteChat}
            onChatRename={renameChat}
            startNewChat={startNewChat}
            messages={messages}
            setAiModels={setAiModels}
            isPremium={isPremium}
            setIsPremium={setIsPremium}
          />
        </div>

        {/* タスクリスト（右側） */}
        <div
          className={`
            fixed top-0 right-0 h-full bg-sidebarBackground dark:bg-sidebarBackground-dark border-l border-gray-200 dark:border-gray-700
            transform transition-transform duration-300 z-20
            ${isTaskbarOpen ? "translate-x-0" : "translate-x-full"}
          `}
          style={{ width: `${taskbarWidth}px` }}
        >
          <TaskList
            isTaskbarOpen={isTaskbarOpen}
            setIsTaskbarOpen={setIsTaskbarOpen}
            activeChatId={chatContext.activeChatId}
            taskbarWidth={taskbarWidth}
          />
          {isTaskbarOpen && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: "-5px",
                width: "5px",
                height: "100%",
                cursor: "col-resize",
              }}
              onMouseDown={handleMouseDown}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Layout;
