// frontend/src/components/common/SideMenu.js
import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  forwardRef,
} from "react";
import {
  Bars3Icon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  LockClosedIcon,
} from "@heroicons/react/24/solid";
import {
  UserIcon,
  CreditCardIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  ComputerDesktopIcon,
  SunIcon,
  MoonIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import ChatTree from "../chat/ChatTree";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import modelIcons from "../../assets/modelIcons";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionModal from "../SubscriptionModal"; // 新しく追加
const SideMenu = forwardRef((props, ref) => {
  const {
    isSidebarOpen,
    setIsSidebarOpen,
    handleLogout,
    email,
    theme,
    setTheme,
    profileImageUrl,
    pastChats,
    activeChatId,
    onChatSelect,
    onChatDelete,
    onChatRename,
    startNewChat,
    messages,
    setAiModels,
    isPremium,
    setIsPremium,
  } = props;
  // ユーザー設定メニューの状態管理
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);
  const [isCustomizeAIModelOpen, setIsCustomizeAIModelOpen] = useState(false); // 追加
  const [selectedAIModels, setSelectedAIModels] = useState([]); // 追加
  const profileMenuRef = useRef(null);
  const profileButtonRef = useRef(null);
  const navigate = useNavigate();
  const customizeAIModelRef = useRef(null);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  // 追加: プレミアムプランモーダルの状態管理
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  // AIモデルのオプション
  const allModelOptions = [
    {
      value: "gpt-4o-2024-08-06",
      label: "GPT-4o",
      description: "OpenAIの汎用性の高い最新モデル",
      supportsStreaming: true,
      availableInFreePlan: true, // 追加
      supportedFileTypes: {
        image: true,
        pdf: true,
        csv: true,
        audio: true,
        word: true, // Wordファイルに対応
        powerpoint: true, // PowerPointファイルに対応
      },
    },
    {
      value: "claude-3-5-haiku-20241022",
      label: "Claude 3.5 Haiku",
      description: "Anthropicの速度に特化したモデル", // 説明を追加
      supportsStreaming: true,
      availableInFreePlan: true,
      supportedFileTypes: {
        image: true,
        pdf: true,
        csv: true,
        audio: false,
        word: false,
        powerpoint: false,
      },
    },
    {
      value: "gemini-1.5-flash",
      label: "Gemini 1.5 Flash",
      description: "Googleの速度に特化したモデル", // 説明を追加
      supportsStreaming: true,
      availableInFreePlan: true,
      supportedFileTypes: {
        image: true,
        pdf: true,
        csv: true,
        audio: false,
        word: false,
        powerpoint: false,
      },
    },
    {
      value: "o1-preview",
      label: "OpenAI o1-preview",
      description: "OpenAIの論理的思考力に特化したモデル",
      supportsStreaming: true,
      availableInFreePlan: false,
      supportedFileTypes: {
        image: false,
        pdf: false,
        csv: false,
        audio: false,
        word: false,
        powerpoint: false,
      },
    },
    {
      value: "claude-3-5-sonnet-20241022",
      label: "Claude 3.5 Sonnet",
      description: "Anthropicの品質に特化したAIモデル",
      supportsStreaming: true,
      availableInFreePlan: false,
      supportedFileTypes: {
        image: true,
        pdf: true,
        csv: true,
        audio: false,
        word: false,
        powerpoint: false,
      },
    },
    {
      value: "gemini-1.5-pro",
      label: "Gemini 1.5 Pro",
      description: "Googleの品質に特化したモデル",
      supportsStreaming: true,
      availableInFreePlan: false,
      supportedFileTypes: {
        image: true,
        pdf: true,
        csv: true,
        audio: false,
        word: false,
        powerpoint: false,
      },
    },
    {
      value: "llama-3.1-sonar-huge-128k-online",
      label: "Perplexity (Llama-3.1)",
      description: "PerplexityのWeb検索可能なAIモデル",
      supportsStreaming: true,
      availableInFreePlan: false,
      supportedFileTypes: {
        image: false,
        pdf: false,
        csv: false,
        audio: false,
        word: false,
        powerpoint: false,
      },
    },
    {
      value: "stable-image-ultra",
      label: "Stable Image Ultra",
      description: "Stability AIの画像生成の最高性能モデル",
      supportsStreaming: false,
      availableInFreePlan: false,
      supportedFileTypes: {
        image: false,
        pdf: false,
        csv: false,
        audio: false,
        word: false,
        powerpoint: false,
      },
    },
    {
      value: "dall-e-3",
      label: "DALL-E 3",
      description: "OpenAIの画像生成モデル",
      supportsStreaming: false,
      availableInFreePlan: false,
      supportedFileTypes: {
        image: false,
        pdf: false,
        csv: false,
        audio: false,
        word: false,
        powerpoint: false,
      },
    },
  ];

  const handleProfileClick = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
    setIsThemeMenuOpen(false);
  };

  const handleCustomizeAIModel = () => {
    setIsCustomizeAIModelOpen(true);
    setIsProfileMenuOpen(false);
  };

  // ポップアップが開いたときにユーザーの設定を取得
  useEffect(() => {
    if (isCustomizeAIModelOpen) {
      axios
        .get("/user/profile")
        .then((res) => {
          setSelectedAIModels(res.data.aiModels || []);
        })
        .catch((err) => {
          console.error("Error fetching user aiModels:", err);
        });
    }
  }, [isCustomizeAIModelOpen]);

  const handleModelCheckboxChange = (value) => {
    if (selectedAIModels.includes(value)) {
      setSelectedAIModels(selectedAIModels.filter((v) => v !== value));
    } else {
      setSelectedAIModels([...selectedAIModels, value]);
    }
  };

  const handleSaveAIModels = () => {
    if (selectedAIModels.length === 0) {
      alert("表示するAIモデルは一つ以上選択する必要があります。");
      return;
    }
    axios
      .put("/user/profile", { aiModels: selectedAIModels })
      .then((res) => {
        setIsCustomizeAIModelOpen(false);
        setAiModels(selectedAIModels); // 追加: 親コンポーネントの状態を更新
      })
      .catch((err) => {
        console.error("Error saving aiModels:", err);
      });
  };

  // メニュー外をクリックしたときにメニューを閉じる
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target) &&
        !profileButtonRef.current.contains(event.target) &&
        !(
          customizeAIModelRef.current &&
          customizeAIModelRef.current.contains(event.target)
        )
      ) {
        setIsProfileMenuOpen(false);
        setIsThemeMenuOpen(false);
        setIsCustomizeAIModelOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getInitial = (email) => {
    return email ? email.charAt(0).toUpperCase() : "";
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  const handleThemeSelect = (selectedTheme) => {
    setTheme(selectedTheme);
    setIsThemeMenuOpen(false);

    // サーバーにテーマを更新
    axios
      .put("/user/profile", { theme: selectedTheme })
      .then((res) => {
        // 必要に応じてレスポンスを処理
      })
      .catch((err) => {
        console.error("Error updating theme in user profile:", err);
      });
  };

  const handleSubscription = () => {
    setIsSubscriptionModalOpen(true);
    setIsProfileMenuOpen(false);
  };

  // 購入処理の関数
  const handlePurchase = async () => {
    try {
      const response = await axios.post("/create-checkout-session");
      const { sessionId } = response.data;

      const stripe = await loadStripe(
        "pk_live_51QFZ4FCOLSLcfPhD5MypXvh0FDnsBcPxDeS7XtzrKrEkX6WkyPH6V2JFck2GrYJUr2TkcnWAQun2SCdK0pmOgbCf00MQ5SNruW"
      );

      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error during purchase:", error);
      alert("購入手続き中にエラーが発生しました。もう一度お試しください。");
    }
  };

  // サブスクリプションのキャンセル処理
  const handleCancelSubscription = async () => {
    try {
      const confirmCancel = window.confirm(
        "プレミアムプランを解約してもよろしいですか？"
      );
      if (confirmCancel) {
        await axios.post("/cancel-subscription");
        alert("プレミアムプランを解約しました。");
        setIsPremium(false); // 状態を更新
        setIsSubscriptionModalOpen(false); // ポップアップを閉じる
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("解約中にエラーが発生しました。もう一度お試しください。");
    }
  };

  return (
    <div
      ref={ref}
      className={`fixed top-0 left-0 h-full w-64 bg-sidebarBackground dark:bg-sidebarBackground-dark border-r border-gray-200 dark:border-gray-700 overflow-visible transition-transform duration-300 ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-64"
      }`}
      style={{ zIndex: 1 }}
    >
      <div className="flex flex-col h-full">
        {/* サイドバーのヘッダー */}
        <div className="flex items-center justify-between p-2 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center">
            <h2 className="text-lg font-bold text-textPrimary dark:text-textPrimary-dark">
              Chat Tree
            </h2>
            <span
              className="
                ml-1
                px-2
                py-1
                text-sm
                font-bold
                rounded-[0.5rem]
                bg-[#383534] text-white
                dark:bg-[#dddddd] dark:text-black
              "
            >
              {isPremium ? "Pro" : "Free"}
            </span>
          </div>
          {isSidebarOpen && (
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="text-textSecondary dark:text-textSecondary-dark hover:text-textPrimary dark:hover:text-textPrimary-dark"
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
          )}
        </div>
        {/* New Chat ボタン */}
        <div className="p-2">
          <button
            onClick={startNewChat}
            disabled={
              pastChats.length > 0 &&
              activeChatId === pastChats[0].id &&
              pastChats[0].title === "New Chat" &&
              messages.length === 0
            }
            className="w-full flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 text-textPrimary dark:text-textPrimary-dark group"
          >
            <ChatBubbleOvalLeftIcon className="h-4 w-4 mr-2" />
            New Chat
          </button>

          {/* 検索ボタンを追加 */}
          <button
            onClick={() => setIsSearchModalOpen(true)}
            className="w-full flex items-center mt-2 p-2 rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 text-textPrimary dark:text-textPrimary-dark group"
          >
            <MagnifyingGlassIcon className="h-4 w-4 mr-2" />
            Search
          </button>
        </div>
        {/* 検索用のポップアップ */}
        {isSearchModalOpen && (
          <Transition appear show={isSearchModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={() => setIsSearchModalOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-[#212121] shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-bold leading-6 text-gray-900 dark:text-white"
                      >
                        会話履歴の検索
                      </Dialog.Title>
                      {/* 検索コンポーネントをここに追加 */}
                      <SearchModalContent
                        onClose={() => setIsSearchModalOpen(false)}
                        onChatSelect={onChatSelect} // 追加
                      />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        )}
        {/* 過去のチャットヘッダー */}
        <div className="p-2">
          <h2 className="text-xl font-bold text-textPrimary dark:text-textPrimary-dark">
            過去のチャット
          </h2>
        </div>
        {/* サイドバーのコンテンツ（スクロール可能） */}
        <div className="flex-1 overflow-y-auto overflow-x-hidden">
          <ChatTree
            pastChats={pastChats.map((chat) => ({
              ...chat,
              lastActivityAt: new Date(chat.lastActivityAt).toLocaleString(),
            }))}
            activeChatId={activeChatId}
            onChatSelect={onChatSelect}
            onChatDelete={onChatDelete}
            onChatRename={onChatRename}
          />
        </div>
        {/* ユーザー設定セクション */}
        <div className="relative bg-sidebarBackground dark:bg-sidebarBackground-dark">
          <div
            className="flex items-center cursor-pointer p-2 flex-nowrap"
            onClick={handleProfileClick}
            ref={profileButtonRef}
          >
            <div className="flex-shrink-0 h-8 w-8 rounded-full overflow-hidden mr-2 bg-gray-200 text-gray-700 flex items-center justify-center">
              {profileImageUrl ? (
                <img
                  src={profileImageUrl}
                  alt="Profile"
                  className="h-full w-full object-cover"
                />
              ) : (
                <span className="font-bold text-lg">{getInitial(email)}</span>
              )}
            </div>
            <span
              className="text-textPrimary dark:text-textPrimary-dark truncate max-w-[300px]"
              onClick={(e) => e.preventDefault()}
            >
              {email.split("@")[0]}
              <span>@</span>
              {email.split("@")[1]}
            </span>
          </div>
          {/* ユーザー設定メニュー */}
          {isProfileMenuOpen && (
            <div
              ref={profileMenuRef}
              className="absolute bottom-full left-1/2 transform -translate-x-1/2 w-48 bg-surface dark:bg-menuBackground-dark border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg z-50"
            >
              <button
                className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-textPrimary dark:text-gray-300"
                onClick={handleProfile}
              >
                <UserIcon className="h-4 w-4 mr-2" />
                ユーザー設定
              </button>
              <button
                className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-textPrimary dark:text-gray-300"
                onClick={handleSubscription} // 修正
              >
                <CreditCardIcon className="h-4 w-4 mr-2" />
                プランの選択
              </button>
              <div className="relative">
                <button
                  className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-textPrimary dark:text-gray-300"
                  onClick={() => setIsThemeMenuOpen(!isThemeMenuOpen)}
                >
                  <Cog6ToothIcon className="h-4 w-4 mr-2" />
                  テーマ
                  <ChevronRightIcon className="h-4 w-4 ml-auto" />
                </button>
                {isThemeMenuOpen && (
                  <div className="absolute top-0 left-full w-40 bg-surface dark:bg-menuBackground-dark border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg z-50">
                    <button
                      className={`flex items-center w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 ${
                        theme === "system"
                          ? "font-bold"
                          : "text-textPrimary dark:text-gray-300"
                      }`}
                      onClick={() => handleThemeSelect("system")}
                    >
                      <ComputerDesktopIcon className="h-4 w-4 mr-2" />
                      システム
                    </button>
                    <button
                      className={`flex items-center w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 ${
                        theme === "light"
                          ? "font-bold"
                          : "text-textPrimary dark:text-gray-300"
                      }`}
                      onClick={() => handleThemeSelect("light")}
                    >
                      <SunIcon className="h-4 w-4 mr-2" />
                      ライト
                    </button>
                    <button
                      className={`flex items-center w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 ${
                        theme === "dark"
                          ? "font-bold"
                          : "text-textPrimary dark:text-gray-300"
                      }`}
                      onClick={() => handleThemeSelect("dark")}
                    >
                      <MoonIcon className="h-4 w-4 mr-2" />
                      ダーク
                    </button>
                  </div>
                )}
              </div>
              <button
                className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-textPrimary dark:text-gray-300"
                onClick={handleCustomizeAIModel}
              >
                <AdjustmentsHorizontalIcon className="h-4 w-4 mr-2" />
                AIモデルのカスタマイズ
              </button>
              <button
                onClick={handleLogout}
                className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-messageUser dark:hover:bg-gray-700 text-error"
              >
                <ArrowRightOnRectangleIcon className="h-4 w-4 mr-2" />
                サインアウト
              </button>
            </div>
          )}
        </div>
      </div>
      {/* カスタマイズ用のポップアップ */}
      {isCustomizeAIModelOpen && (
        <Transition appear show={isCustomizeAIModelOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setIsCustomizeAIModelOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    ref={customizeAIModelRef}
                    className="w-full max-w-4xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-[#212121] shadow-xl rounded-2xl"
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-bold leading-6 text-gray-900 dark:text-white"
                    >
                      AIモデルのカスタマイズ
                    </Dialog.Title>
                    <div className="mt-4 overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead>
                          <tr>
                            <th className="px-4 py-2 bg-gray-50 dark:bg-gray-800 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              選択
                            </th>
                            <th className="px-4 py-2 bg-gray-50 dark:bg-gray-800 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              モデル名
                            </th>
                            <th className="px-4 py-2 bg-gray-50 dark:bg-gray-800 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              説明
                            </th>
                            {/* 新しい列を追加 */}
                            <th className="px-4 py-2 bg-gray-50 dark:bg-gray-800 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              画像
                            </th>
                            <th className="px-4 py-2 bg-gray-50 dark:bg-gray-800 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              PDF
                            </th>
                            <th className="px-4 py-2 bg-gray-50 dark:bg-gray-800 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              CSV
                            </th>
                            <th className="px-4 py-2 bg-gray-50 dark:bg-gray-800 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              音声
                            </th>
                            <th className="px-4 py-2 text-center bg-gray-50 dark:bg-gray-800 text-xs font-medium text-gray-500 dark:text-gray-300 tracking-wider">
                              Word
                            </th>
                            <th className="px-4 py-2 text-center bg-gray-50 dark:bg-gray-800 text-xs font-medium text-gray-500 dark:text-gray-300 tracking-wider">
                              PowerPoint
                            </th>
                            <th className="px-4 py-2 text-center bg-gray-50 dark:bg-gray-800 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                              ストリーミング対応
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                          {allModelOptions.map((model) => {
                            const isModelLocked =
                              !isPremium && !model.availableInFreePlan;
                            return (
                              <tr
                                key={model.value}
                                className={isModelLocked ? "opacity-50" : ""}
                              >
                                <td className="px-4 py-2 whitespace-nowrap">
                                  <input
                                    type="checkbox"
                                    id={`model-${model.value}`}
                                    checked={selectedAIModels.includes(
                                      model.value
                                    )}
                                    onChange={() =>
                                      handleModelCheckboxChange(model.value)
                                    }
                                    disabled={isModelLocked}
                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                  />
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap">
                                  <label
                                    htmlFor={`model-${model.value}`}
                                    className="flex items-center text-sm text-gray-900 dark:text-gray-300"
                                  >
                                    <img
                                      src={modelIcons[model.value]}
                                      alt={model.label}
                                      className="h-6 w-6 mr-2"
                                    />
                                    {model.label}
                                    {isModelLocked && (
                                      <LockClosedIcon className="h-4 w-4 ml-1 text-gray-500" />
                                    )}
                                  </label>
                                </td>
                                <td className="px-4 py-2 whitespace-normal text-sm text-gray-500 dark:text-gray-400">
                                  {model.description}
                                </td>
                                {/* 対応ファイルタイプ */}
                                <td className="px-4 py-2 text-center">
                                  {model.supportedFileTypes.image ? "○" : "×"}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {model.supportedFileTypes.pdf ? "○" : "×"}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {model.supportedFileTypes.csv ? "○" : "×"}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {model.supportedFileTypes.audio ? "○" : "×"}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {model.supportedFileTypes.word ? "○" : "×"}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {model.supportedFileTypes.powerpoint
                                    ? "○"
                                    : "×"}
                                </td>
                                <td className="px-4 py-2 text-center">
                                  {model.supportsStreaming ? "○" : "×"}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 border border-transparent rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-2"
                        onClick={() => setIsCustomizeAIModelOpen(false)}
                      >
                        キャンセル
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 dark:bg-blue-500 border border-transparent rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={handleSaveAIModels}
                      >
                        保存
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      {/* 追加: プレミアムプランのポップアップ */}
      {isSubscriptionModalOpen && (
        <SubscriptionModal
          isSubscriptionModalOpen={isSubscriptionModalOpen}
          setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
          isPremium={isPremium}
          handlePurchase={handlePurchase}
          handleCancelSubscription={handleCancelSubscription}
        />
      )}
    </div>
  );
});

// 新しいコンポーネントを追加
function SearchModalContent({ onClose, onChatSelect }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async () => {
    setIsSearching(true);
    try {
      console.log("Starting search with query:", searchQuery);
      const response = await axios.get("/search-messages", {
        params: { query: searchQuery },
        // withCredentials: true, // 一旦コメントアウト
      });
      console.log("Search response:", response);
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error searching messages:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
      }
      alert("検索に失敗しました。再度お試しください。");
    } finally {
      setIsSearching(false);
    }
  };
  const handleResultClick = (chatId) => {
    onClose();
    onChatSelect(chatId);
  };

  return (
    <div className="mt-4">
      <div className="flex">
        <input
          type="text"
          className="flex-1 p-2 border rounded"
          placeholder="検索キーワードを入力してください"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSearch();
            }
          }}
        />
        <button
          onClick={handleSearch}
          className="ml-2 px-4 py-2 bg-blue-600 text-white rounded"
        >
          検索
        </button>
      </div>
      {isSearching && <p className="mt-2">検索中...</p>}
      <div className="mt-4 max-h-80 overflow-y-auto">
        {searchResults.map((result) => (
          <div
            key={result._id}
            className="p-2 border-b cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
            onClick={() => handleResultClick(result.chatId)}
          >
            <p className="text-sm text-gray-700 dark:text-gray-300">
              {result.content}
            </p>
            <p className="text-xs text-gray-500">
              チャットタイトル: {result.chatTitle}
            </p>
            <p className="text-xs text-gray-500">
              投稿日時: {new Date(result.createdAt).toLocaleString()}
            </p>
          </div>
        ))}
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded"
        >
          閉じる
        </button>
      </div>
    </div>
  );
}

export default SideMenu;
