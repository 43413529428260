// App.js
import React, { useState, useEffect } from "react";
import ChatInterface from "./components/chat/ChatInterface";
import Login from "./pages/Login";
import Register from "./pages/Register";
import axios from "axios";
import Profile from "./pages/Profile";
import Layout from "./components/common/Layout";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import VerifyEmail from "./pages/VerifyEmail";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Cookies from "js-cookie";
import { AppContext } from "./AppContext";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import SpecifiedCommercialTransactionsLaw from "./pages/SpecifiedCommercialTransactionsLaw";
import SubscriptionSuccess from "./pages/SubscriptionSuccess";
import SubscriptionCancel from "./pages/SubscriptionCancel";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

function App() {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken") || ""
  );
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [email, setEmail] = useState("");
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "system");
  const [activeChatId, setActiveChatId] = useState(null);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const root = window.document.documentElement;
    if (theme === "dark") {
      root.classList.add("dark");
    } else if (theme === "light") {
      root.classList.remove("dark");
    } else {
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      if (mediaQuery.matches) {
        root.classList.add("dark");
      } else {
        root.classList.remove("dark");
      }
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setAuthToken("");
    setIsAuthenticated(false);
    axios.defaults.headers.common["Authorization"] = "";
    Cookies.remove("jwt");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      localStorage.setItem("authToken", token);
      setAuthToken(token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      const newURL = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newURL);
    } else {
      const storedToken = localStorage.getItem("authToken");
      if (storedToken) {
        setAuthToken(storedToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${storedToken}`;
      }
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
      axios
        .get("/user")
        .then((response) => {
          setEmail(response.data.email);
          setIsAuthenticated(true);
          setUser(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          handleLogout();
        });
    } else {
      setIsAuthenticated(false);
    }
  }, [authToken]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <AppContext.Provider
      value={{ authToken, activeChatId, setActiveChatId, user, setUser }}
    >
      <Router>
        {isAuthenticated ? (
          <Routes>
            <Route
              path="/"
              element={
                <Layout
                  handleLogout={handleLogout}
                  email={email}
                  theme={theme}
                  setTheme={setTheme}
                />
              }
            >
              {/* デフォルトのインデックスルートを追加 */}
              <Route index element={<Navigate to="/chat" replace />} />
              <Route
                path="chat"
                element={
                  <ChatInterface
                    authToken={authToken} // 追加
                  />
                }
              />
              <Route path="chat/:chatId" element={<ChatInterface />} />
              <Route
                path="profile"
                element={
                  <Profile
                    handleLogout={handleLogout}
                    email={email}
                    theme={theme}
                    setTheme={setTheme}
                  />
                }
              />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-of-service" element={<TermsOfService />} />
              <Route
                path="specified-commercial-transactions-law"
                element={<SpecifiedCommercialTransactionsLaw />}
              />
              <Route path="success" element={<SubscriptionSuccess />} />
              <Route path="cancel" element={<SubscriptionCancel />} />
            </Route>
            {/* ルート外でのワイルドカードルートを設定 */}
            <Route path="*" element={<Navigate to="/chat" replace />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/login"
              element={<Login setAuthToken={setAuthToken} />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route
              path="/specified-commercial-transactions-law"
              element={<SpecifiedCommercialTransactionsLaw />}
            />
            {/* 認証されていないユーザーのデフォルトリダイレクト */}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
      </Router>
    </AppContext.Provider>
  );
}

export default App;
