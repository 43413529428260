// TermsOfService.js
import React from "react";

function TermsOfService() {
  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">利用規約</h1>
      <p className="mb-4">更新日：2024年8月28日</p>

      <p className="mb-4">
        Chat
        Tree（以下、「当社」といいます）をご利用いただき、ありがとうございます！
      </p>

      <p className="mb-4">
        本利用規約（以下、「本規約」といいます）は、当社が提供するアプリケーション、プログラム、ソフトウェア、ツール、データ、ドキュメンテーション、ウェブサイト（以下、「サービス」といいます）の利用に関する条件を定めるものです。サービスをご利用になることで、本規約に同意したものとみなされます。プライバシーポリシーは、個人情報の収集と利用について説明しています。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">1. 登録とアクセス</h2>
      <p className="mb-4">
        18歳未満の場合、サービスの利用には保護者または法定代理人の許可が必要です。他の人物や団体の代理でサービスを利用する場合、その人物または団体の代表者として本規約に同意する権限を持つ必要があります。正確かつ完全な情報を提供してアカウントを登録してください。組織外の他者にアクセス権限やアカウントを提供してはならず、資格情報を使用して発生するすべての活動に責任を負います。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">2. 利用要件</h2>
      <p className="mb-4">
        (a)
        サービスの利用：本規約に従ってサービスを利用できます。当社およびその関連会社は、サービスに関するすべての権利、所有権、利益を所有します。
      </p>
      <p className="mb-4">
        (b)
        フィードバック：フィードバック、コメント、アイデア、提案、改善提案を提供いただけると幸いです。提供いただいた場合、当社はそれを無制限かつ無償で使用できるものとします。
      </p>
      <p className="mb-4">
        (c) 制限事項：
        <ul className="list-disc list-inside">
          <li>
            他人の権利を侵害し、不正使用し、違反する方法でサービスを利用してはなりません。
          </li>
          <li>
            ソースコードやモデル、アルゴリズム、システムの構成要素を逆アセンブル、逆コンパイル、デコンパイル、翻訳、またはそれらを発見しようとしてはなりません（関連法に反する場合を除く）。
          </li>
          <li>
            サービスの出力を使用して、当社と競合するモデルを開発してはなりません。
          </li>
          <li>
            APIを通じて許可されていない限り、スクレイピングやデータ抽出などの自動化された方法でデータや出力を取得してはなりません。
          </li>
          <li>
            サービスの出力が人間によって生成されたものであると偽ってはなりません。
          </li>
          <li>
            当社の事前の同意なしにAPIキーを購入、売却、移転してはなりません。
          </li>
          <li>
            13歳未満の子供または適用対象年齢未満の子供の個人情報を送信してはなりません。
          </li>
          <li>商業目的での使用は禁止されています。</li>
        </ul>
      </p>
      <p className="mb-4">
        (d)
        第三者サービス：サービスと関連して使用する第三者のソフトウェア、サービス、その他の製品は、それぞれ独自の規定が適用されます。当社は第三者製品について責任を負いません。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">3. コンテンツ</h2>
      <p className="mb-4">
        (a)
        あなたのコンテンツ：サービスに入力（以下、「入力」といいます）を提供し、入力に基づいてサービスが生成し返される出力（以下、「出力」といいます）を受け取ることができます。入力と出力は共に「コンテンツ」といいます。当事者間および適用法に準じて、入力についてはすべての権利を所有します。Chat
        Treeはサービスの提供と維持、適用法の遵守、およびポリシーの執行のためにコンテンツを使用する場合があります。コンテンツが適用法または本規約に違反しないよう確認する責任はあなたにあります。
      </p>
      <p className="mb-4">
        (b)
        正確性：人工知能と機械学習は急速に進化する分野です。当社はサービスをより正確で信頼性のあるものに改善するために取り組んでいます。機械学習の特性上、サービスの使用によって正確でない出力が生じる場合があります。適切な用途において出力の正確性を評価し、人間によるレビューを行ってください。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">4. 料金と支払い</h2>
      <p className="mb-4">
        (a)
        料金と請求：アカウントに課金されるすべての料金（以下、「料金」といいます）は、該当する価格ページに記載された価格と条件、または書面での合意に従って支払われます。料金を支払うために、有効な支払方法を提供する必要があります。支払いが完了しない場合、通知の上でサービスへのアクセスを一時停止する場合があります。支払いは、本規約に定める通りに返金されません。
      </p>
      <p className="mb-4">
        (b)
        税金：料金には税金が含まれていません。購入に関連するすべての税金はあなたの責任です。当社はそのような税金について請求書を送付する場合があります。
      </p>
      <p className="mb-4">
        (c)
        価格変更：価格変更は、アカウントへの通知またはウェブサイトへの掲示によって行われる場合があります。価格の変更は、掲示されてから14日後に有効となります。
      </p>
      <p className="mb-4">
        (d)
        紛争と遅延支払い：料金や税金に異議を申し立てる場合は、異議を申し立てる日から30日以内にsupport@chat-tree.comまでご連絡ください。
      </p>
      <p className="mb-4">
        (e)
        無料ティア：サービスの無料ティアで提供されるクレジットを利用するために複数のアカウントを作成してはなりません。適切でない利用と判断した場合、通常の料金を請求したり、サービスへのアクセスを停止したりすることがあります。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">
        5. 機密保持、セキュリティ、データ保護
      </h2>
      <p className="mb-4">
        (a)
        機密保持：あなたは当社、その関連会社、第三者の機密情報にアクセスする場合があります。機密情報は、本規約で許可された範囲内でのみ使用してください。機密情報を第三者に開示してはなりません。
      </p>
      <p className="mb-4">
        (b)
        セキュリティ：サービスへのアクセスと利用を安全に保つための合理的な措置を実施してください。脆弱性や侵害を発見した場合は、速やかに当社に連絡してください。
      </p>
      <p className="mb-4">
        (c)
        個人データの処理：サービスを使用して個人データを処理する場合、適法なプライバシー通知を提供し、必要な同意を取得する義務があります。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">6. 期間と終了</h2>
      <p className="mb-4">
        (a)
        終了・中止：本規約は、最初にサービスを使用した時点で効力を持ち、終了するまで有効です。あなたはいつでもサービスの利用を中止することで本規約を終了できます。当社は最低でも30日前に通知することで、いつでも本規約を終了できます。違反があった場合、即座に終了する場合があります。
      </p>
      <p className="mb-4">
        (b)
        終了の効果：終了時にはサービスの使用を停止し、機密情報を返還または破棄してください。本規約の性質上、終了後も存続する条項は継続して有効です。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">7. 免責事項と責任の制限</h2>
      <p className="mb-4">
        (a)
        免責事項：サービスは「現状のまま」提供されます。適用法によって禁止されていない限り、当社およびその関連会社は、サービスに関するいかなる保証も行いません。
      </p>
      <p className="mb-4">
        (b)
        責任の制限：当社および関連会社は、いかなる損害に対しても一切責任を負いません。責任の限定は、適用法で許容される範囲においてのみ適用されます。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">8. 適用法と管轄</h2>
      <p className="mb-4">
        本規約は、日本法に準拠し解釈されます。本規約に関連する紛争は、東京地方裁判所を専属的合意管轄裁判所とします。
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">9. 一般条項</h2>
      <p className="mb-4">
        (a)
        当事者の関係：本規約は、あなたと当社との関係を規定するものであり、第三者に利益をもたらすものではありません。
      </p>
      <p className="mb-4">
        (b)
        通知：当社は、電子メール、ウェブサイトへの掲示、またはサービス内の通知として、あなたに連絡する場合があります。
      </p>
      <p className="mb-4">
        (c)
        譲渡：あなたは当社の事前の書面承諾なしに、本規約に基づく権利や義務を第三者に譲渡してはなりません。
      </p>
      <p className="mb-4">
        (d)
        完全合意：本規約は、当事者間の完全な合意を構成し、以前の口頭または書面による合意に優先します。
      </p>
      <p className="mb-4">
        (e)
        放棄：当事者の権利放棄は、明示的な書面による同意がない限り有効ではありません。
      </p>
      <p className="mb-4">
        (f)
        分離可能性：本規約のいずれかの条項が無効または執行不能と判断された場合、残りの条項は引き続き有効です。
      </p>
      <p className="mb-4">
        (g) 見出し：見出しは便宜上のものであり、本規約の解釈には影響しません。
      </p>

      <p className="mt-6">
        以上がChat
        Treeの利用規約です。本規約に同意していただける場合は、引き続きサービスをご利用いただけます。ご不明な点がございましたら、お気軽にお問い合わせください。
      </p>

      <p className="mt-4">ありがとうございました。</p>
    </div>
  );
}

export default TermsOfService;
