// modelIcons.js
import claudeIcon from "./claude-icon.png";
import gptIcon from "./chatgpt-icon.png";
import geminiIcon from "./gemini-icon.png";
import perplexityIcon from "./perplexity-icon.png";
import stabilityIcon from "./stability-icon.png";
import dalleIcon from "./dall-e-icon.png";
import o1Icon from "./chatgpt-o1-icon.png"; // 追加

const modelIcons = {
  "claude-3-5-sonnet-20241022": claudeIcon,
  "claude-3-5-haiku-20241022": claudeIcon, // 追加
  "gpt-4o-2024-08-06": gptIcon,
  "gemini-1.5-pro": geminiIcon,
  "gemini-1.5-flash": geminiIcon, // 追加
  "llama-3.1-sonar-huge-128k-online": perplexityIcon,
  "stable-image-ultra": stabilityIcon,
  "dall-e-3": dalleIcon,
  "o1-preview": o1Icon, // 追加
};

export default modelIcons;
