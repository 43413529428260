// src/pages/Register.js

import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // 追加
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // 追加

  // パスワードの強度を評価する関数
  const evaluatePasswordStrength = (pwd) => {
    if (pwd.length < 6) {
      setPasswordStrength("弱い");
    } else if (pwd.length < 10) {
      setPasswordStrength("普通");
    } else {
      setPasswordStrength("強い");
    }
  };

  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setPassword(pwd);
    evaluatePasswordStrength(pwd);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // クライアントサイドのバリデーション
    if (!email || !password || !confirmPassword) {
      setError("全ての項目を入力してください。");
      return;
    }

    if (password !== confirmPassword) {
      setError("パスワードが一致しません。");
      return;
    }

    try {
      setIsSubmitting(true);
      setError("");
      setMessage("");

      const response = await axios.post(
        "/register",
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setMessage(
        response.data.message ||
          "仮登録が完了しました。メールをご確認ください。"
      );

      // 2秒後にメール認証ページにリダイレクト
      setTimeout(() => {
        navigate("/verify-email", { state: { email } });
      }, 2000);
    } catch (error) {
      console.error("Error registering:", error);
      setError(
        error.response?.data?.error ||
          "ユーザー登録に失敗しました。もう一度お試しください。"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          新規登録
        </h2>
        {message && (
          <p className="text-green-500 mb-4 text-center font-semibold">
            {message}
          </p>
        )}
        {error && (
          <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
        )}
        <form onSubmit={handleRegister} noValidate>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 dark:text-gray-300 mb-2"
            >
              メールアドレス
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={`w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                error ? "border-red-500" : "border-gray-300"
              } bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white`}
              placeholder="メールアドレスを入力"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 dark:text-gray-300 mb-2"
            >
              パスワード
            </label>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                required
                className={`w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                  error ? "border-red-500" : "border-gray-300"
                } bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white pr-12`}
                placeholder="パスワードを入力"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-3 flex items-center text-gray-500 dark:text-gray-400"
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5" />
                ) : (
                  <EyeIcon className="h-5 w-5" />
                )}
              </button>
            </div>
            {password && (
              <p
                className={`mt-2 text-sm ${
                  passwordStrength === "強い"
                    ? "text-green-500"
                    : passwordStrength === "普通"
                    ? "text-yellow-500"
                    : "text-red-500"
                }`}
              >
                パスワードの強度: {passwordStrength}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-gray-700 dark:text-gray-300 mb-2"
            >
              パスワード（確認）
            </label>
            <input
              id="confirmPassword"
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className={`w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                error ? "border-red-500" : "border-gray-300"
              } bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white`}
              placeholder="パスワードを再入力"
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full bg-primary text-white py-3 px-4 rounded-lg hover:bg-primary-dark transition-colors duration-200 font-semibold mt-4 ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? "登録中..." : "登録"}
          </button>
        </form>
        <p className="mt-6 text-center text-gray-600 dark:text-gray-400">
          既にアカウントをお持ちですか？{" "}
          <Link
            to="/login"
            className="text-blue-600 dark:text-blue-400 hover:underline"
          >
            ログインはこちら
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
