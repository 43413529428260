// CodeBlock.js
import React, { useState, useCallback, useEffect, useRef } from "react";
import { ClipboardIcon } from "@heroicons/react/24/solid";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlock = React.memo(({ language, value }) => {
  const copiedRef = useRef(false);
  const [copied, setCopiedState] = useState(false);

  const setCopied = (state) => {
    copiedRef.current = state;
    setCopiedState(state);
  };

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [value]);

  useEffect(() => {
    setCopied(copiedRef.current);
  }, [value]);

  return (
    <div className="code-block-container overflow-x-auto max-w-full w-full rounded-lg not-prose relative mb-4">
      {/* ヘッダー部分 */}
      <div className="flex justify-between items-center bg-[#2f2f2f] px-4 py-2 rounded-t-lg">
        <span className="text-sm font-medium text-gray-100">{language}</span>
        <button
          className="copy-button absolute top-1 right-2 text-gray-100 flex items-center"
          onClick={copyToClipboard}
        >
          {copied ? (
            "Copied!"
          ) : (
            <>
              <ClipboardIcon className="h-4 w-4 inline mr-1" />
              Copy
            </>
          )}
        </button>
      </div>
      {/* コードブロック部分 */}
      <SyntaxHighlighter
        style={vscDarkPlus}
        language={language}
        PreTag="div"
        wrapLongLines={true} // ここを追加
        codeTagProps={{
          style: {
            fontSize: "1.25em",
            fontWeight: "400",
            lineHeight: "1.7142857",
            whiteSpace: "pre-wrap", // 折り返し
            overflowWrap: "break-word", // 折り返し可能に
          },
        }}
        customStyle={{
          margin: 0,
          padding: "0.5rem 1rem",
          overflowX: "auto", // 横スクロールを許可
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          maxWidth: "100%",
          borderRadius: "0 0 0.5rem 0.5rem",
        }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
});

export default CodeBlock;
