// Header.js
import React from "react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

function Header({
  currentChatTitle,
  setIsSidebarOpen,
  isSidebarOpen,
  setIsTaskbarOpen,
  isTaskbarOpen,
}) {
  return (
    <header className="fixed top-0 left-0 right-0 z-10 flex items-center justify-between p-2 bg-white dark:bg-background-dark border-b border-gray-200 dark:border-gray-700">
      {/* 左側のアイコンまたはスペース */}
      {!isSidebarOpen ? (
        <button
          onClick={() => setIsSidebarOpen(true)}
          className="text-textSecondary dark:text-textSecondary-dark hover:text-textPrimary dark:hover:text-textPrimary-dark"
        >
          <Bars3Icon className="h-6 w-6" />
        </button>
      ) : (
        // アイコンが非表示のときにスペースを確保
        <div className="h-6 w-6"></div>
      )}
      {/* タイトル */}
      <h2 className="text-lg font-bold text-textPrimary dark:text-textPrimary-dark text-center flex-grow">
        {currentChatTitle}
      </h2>
      {/* 右側のアイコンまたはスペース */}
      {!isTaskbarOpen ? (
        <button
          onClick={() => setIsTaskbarOpen(true)}
          className="text-textSecondary dark:text-textSecondary-dark hover:text-textPrimary dark:hover:text-textPrimary-dark"
        >
          <CheckCircleIcon className="h-6 w-6" />
        </button>
      ) : (
        // タスクアイコンが非表示のときにスペースを確保
        <div className="h-6 w-6"></div>
      )}
    </header>
  );
}

export default Header;
