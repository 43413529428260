// frontend/src/pages/SubscriptionCancel.js
import React from "react";
import { useNavigate } from "react-router-dom";

function SubscriptionCancel() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/chat"); // ホームに戻る
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background dark:bg-background-dark text-textPrimary dark:text-textPrimary-dark">
      <h1 className="text-3xl font-bold mb-4">購入がキャンセルされました</h1>
      <p className="text-lg mb-8">
        プレミアムプランの購入がキャンセルされました。再度お試しください。
      </p>
      <button
        onClick={handleGoBack}
        className="px-6 py-3 bg-blue-600 text-white rounded hover:bg-blue-700"
      >
        戻る
      </button>
    </div>
  );
}

export default SubscriptionCancel;
