// SpecifiedCommercialTransactionsLaw.js
import React from "react";

function SpecifiedCommercialTransactionsLaw() {
  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">特定商取引法に基づく表記</h1>
      <table className="w-full mb-4">
        <tbody>
          <tr>
            <td className="font-bold py-2">販売業者の名称</td>
            <td className="py-2">Copelnix合同会社</td>
          </tr>
          <tr>
            <td className="font-bold py-2">所在地</td>
            <td className="py-2">〒116-0014 東京都荒川区東日暮里5-16-1-1906</td>
          </tr>
          <tr>
            <td className="font-bold py-2">電話番号</td>
            <td className="py-2">
              070-8513-0673
              <br />
              受付時間: 10:00-18:00（土日祝日を除く）
            </td>
          </tr>
          <tr>
            <td className="font-bold py-2">メールアドレス</td>
            <td className="py-2">info@copelnix.com</td>
          </tr>
          <tr>
            <td className="font-bold py-2">運営統括責任者</td>
            <td className="py-2">鈴木 爽太郎</td>
          </tr>
          <tr>
            <td className="font-bold py-2">追加手数料等の追加料金</td>
            <td className="py-2">なし</td>
          </tr>
          <tr>
            <td className="font-bold py-2">交換および返品（返金ポリシー）</td>
            <td className="py-2">
              <p>＜お客様都合によるキャンセル・返金＞</p>
              <p>
                デジタルコンテンツの性質上、お客様都合によるキャンセル・返金はお受けできません。
              </p>
              <p>＜サービスに不具合がある場合＞</p>
              <p>
                当社の費用負担にて返金または修正対応いたします。まずはお問い合わせください。
              </p>
            </td>
          </tr>
          <tr>
            <td className="font-bold py-2">商品引渡し時期</td>
            <td className="py-2">ご購入後、即時にご利用いただけます。</td>
          </tr>
          <tr>
            <td className="font-bold py-2">受け付け可能な決済手段</td>
            <td className="py-2">クレジットカード</td>
          </tr>
          <tr>
            <td className="font-bold py-2">決済期間</td>
            <td className="py-2">
              クレジットカード決済はご注文時に処理されます。
            </td>
          </tr>
          <tr>
            <td className="font-bold py-2">販売価格</td>
            <td className="py-2">各プランページに記載の金額（消費税込み）</td>
          </tr>
          <tr>
            <td className="font-bold py-2">ソフトウェアの動作環境</td>
            <td className="py-2">
              iOS 13.0以上、Windows 7以上、Android 10以上
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SpecifiedCommercialTransactionsLaw;
