// frontend/src/components/common/TaskList.js
import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
  useContext,
} from "react";
import { AppContext } from "../../AppContext";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  MeasuringStrategy,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import CodeBlock from "../chat/CodeBlock";
import { processStream } from "../../utils/streamProcessor";
import TaskEditor from "./TaskEditor";
import {
  Calendar1,
  CalendarRange,
  CalendarDays,
  FileCheck,
} from "lucide-react";

function TaskList({
  isTaskbarOpen,
  setIsTaskbarOpen,
  activeChatId,
  taskbarWidth,
}) {
  const [tasks, setTasks] = useState([]);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [taskFormData, setTaskFormData] = useState({
    title: "",
    description: "",
    dueDate: null,
    priority: "normal",
    labels: [],
  });
  const [filter, setFilter] = useState("all");
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [promptContent, setPromptContent] = useState("");
  const [isGeneratingPrompt, setIsGeneratingPrompt] = useState(false);
  const { authToken, user } = useContext(AppContext);

  // 今日やったことリスト関連の状態
  const [isDailyReportModalOpen, setIsDailyReportModalOpen] = useState(false);
  const [dailyReportContent, setDailyReportContent] = useState("");

  // 今週やったことリスト関連の状態
  const [isWeeklyReportModalOpen, setIsWeeklyReportModalOpen] = useState(false);
  const [weeklyReportContent, setWeeklyReportContent] = useState("");

  // 今月やったことリスト関連の状態
  const [isMonthlyReportModalOpen, setIsMonthlyReportModalOpen] =
    useState(false);
  const [monthlyReportContent, setMonthlyReportContent] = useState("");

  const [isDidListOpen, setIsDidListOpen] = useState(false);
  const didListRef = useRef(null);

  const saveTimerRef = useRef(null);

  useEffect(() => {
    fetchTasks();
  }, []);

  // クリックイベントでサブメニューを閉じる
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isDidListOpen &&
        didListRef.current &&
        !didListRef.current.contains(event.target) &&
        !event.target.closest("#didListToggleButton")
      ) {
        setIsDidListOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDidListOpen]);

  const fetchTasks = () => {
    axios
      .get("/tasks")
      .then((res) => {
        const tasksWithDate = res.data.map((task) => ({
          ...task,
          dueDate: task.dueDate ? new Date(task.dueDate) : null,
          labels: task.labels || [], // ここを追加
        }));
        setTasks(tasksWithDate);
      })
      .catch((err) => {
        console.error("タスクの取得中にエラーが発生しました:", err);
      });
  };

  const openTaskModal = (task = null) => {
    setEditingTask(task);
    setTaskFormData(
      task
        ? {
            title: task.title,
            description: task.description,
            dueDate: task.dueDate ? new Date(task.dueDate) : null,
            priority: task.priority,
            labels: task.labels || [],
          }
        : {
            title: "",
            description: "",
            dueDate: null,
            priority: "normal",
            labels: [],
          }
    );
    setIsTaskModalOpen(true);
  };

  const closeTaskModal = () => {
    if (saveTimerRef.current) {
      clearTimeout(saveTimerRef.current);
    }
    setIsTaskModalOpen(false);
    // 状態のリセットを削除
  };

  const updateTaskTitle = (taskId, newTitle) => {
    setTasks(
      tasks.map((task) =>
        task._id === taskId ? { ...task, title: newTitle } : task
      )
    );
  };

  const handleTaskFormChange = (e) => {
    const { name, value } = e.target;
    setTaskFormData({ ...taskFormData, [name]: value });
  };

  const handleDateChange = (date) => {
    setTaskFormData({ ...taskFormData, dueDate: date });
  };
  const saveTask = useCallback(() => {
    const { title, description, dueDate, priority, labels } = taskFormData;
    if (title.trim() === "") return;

    const taskData = {
      title,
      description,
      dueDate,
      priority,
      labels,
    };

    if (editingTask) {
      axios
        .put(`/tasks/${editingTask._id}`, taskData)
        .then((res) => {
          setTasks(
            tasks.map((task) =>
              task._id === editingTask._id ? res.data : task
            )
          );
        })
        .catch((err) => {
          console.error("タスクの更新中にエラーが発生しました:", err);
        });
    }
  }, [taskFormData, editingTask, tasks]);

  useEffect(() => {
    if (editingTask) {
      if (saveTimerRef.current) {
        clearTimeout(saveTimerRef.current);
      }
      saveTimerRef.current = setTimeout(() => {
        saveTask();
      }, 500);
    }
  }, [taskFormData, editingTask, saveTask]);

  const updateTaskCompletion = (taskId, completed) => {
    axios
      .put(`/tasks/${taskId}`, { completed })
      .then((res) => {
        setTasks(tasks.map((task) => (task._id === taskId ? res.data : task)));
      })
      .catch((err) => {
        console.error("タスクの更新中にエラーが発生しました:", err);
      });
  };

  const deleteTask = (taskId) => {
    axios
      .delete(`/tasks/${taskId}`)
      .then((res) => {
        setTasks(tasks.filter((task) => task._id !== taskId));
      })
      .catch((err) => {
        console.error("タスクの削除中にエラーが発生しました:", err);
      });
  };

  const filteredTasks = tasks.filter((task) => {
    if (filter === "all") return true;
    if (filter === "completed") return task.completed;
    if (filter === "incomplete") return !task.completed;
    return true;
  });

  /// ドラッグ＆ドロップ用のセンサーを設定
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  // ドラッグ中のタスクを管理する状態を追加
  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (active.id !== over.id) {
      const oldIndex = tasks.findIndex((task) => task._id === active.id);
      const newIndex = tasks.findIndex((task) => task._id === over.id);

      const newTasks = arrayMove(tasks, oldIndex, newIndex);
      setTasks(newTasks);

      // 新しい順序をバックエンドに送信
      const orderedTaskIds = newTasks.map((task) => task._id);
      axios
        .put("/tasks/order", { orderedTaskIds })
        .then((res) => {
          // 成功時の処理（必要に応じて）
        })
        .catch((err) => {
          console.error("タスクの順序更新中にエラーが発生しました:", err);
        });
    }
  };

  const generatePrompt = (task) => {
    if (!activeChatId) {
      console.error("チャットが選択されていません");
      return;
    }

    setIsPromptModalOpen(true);
    setPromptContent("");
    setIsGeneratingPrompt(true);

    fetch(
      `${process.env.REACT_APP_API_URL}/generate-prompt?taskId=${task._id}&chatId=${activeChatId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
      .then((response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        function read() {
          reader.read().then(({ done, value }) => {
            if (done) {
              setIsGeneratingPrompt(false);
              return;
            }
            const chunk = decoder.decode(value, { stream: true });
            const lines = chunk.split("\n\n");
            for (const line of lines) {
              if (line.startsWith("data: ")) {
                const dataStr = line.slice(6).trim();
                if (dataStr === "[DONE]") {
                  setIsGeneratingPrompt(false);
                  return;
                } else {
                  try {
                    const data = JSON.parse(dataStr);
                    if (data.content) {
                      setPromptContent((prev) => prev + data.content);
                    } else if (data.error) {
                      console.error("プロンプト生成エラー:", data.error);
                      setIsGeneratingPrompt(false);
                    }
                  } catch (error) {
                    console.error("JSON解析エラー:", error);
                  }
                }
              }
            }
            read();
          });
        }
        read();
      })
      .catch((error) => {
        console.error("プロンプトの取得中にエラーが発生しました:", error);
        setIsGeneratingPrompt(false);
      });
  };

  const generateDailyReport = () => {
    setIsDailyReportModalOpen(true);
    setDailyReportContent("");

    fetch(`${process.env.REACT_APP_API_URL}/generate-daily-report`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(async (response) => {
        await processStream(
          response,
          (content) => {
            setDailyReportContent((prev) => prev + content);
          },
          () => {
            // ストリーミング完了時の処理（必要に応じて）
          },
          (error) => {
            console.error("今日やったことリスト生成エラー:", error);
          }
        );
      })
      .catch((error) => {
        console.error(
          "今日やったことリストの取得中にエラーが発生しました:",
          error
        );
      });
  };

  // 今週やったことリスト生成関数
  const generateWeeklyReport = () => {
    setIsWeeklyReportModalOpen(true);
    setWeeklyReportContent("");

    fetch(`${process.env.REACT_APP_API_URL}/generate-weekly-report`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(async (response) => {
        await processStream(
          response,
          (content) => {
            setWeeklyReportContent((prev) => prev + content);
          },
          () => {
            // ストリーミング完了時の処理（必要に応じて）
          },
          (error) => {
            console.error("今週やったことリスト生成エラー:", error);
          }
        );
      })
      .catch((error) => {
        console.error(
          "今週やったことリストの取得中にエラーが発生しました:",
          error
        );
      });
  };

  // 今月やったことリスト生成関数
  const generateMonthlyReport = () => {
    setIsMonthlyReportModalOpen(true);
    setMonthlyReportContent("");

    fetch(`${process.env.REACT_APP_API_URL}/generate-monthly-report`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(async (response) => {
        await processStream(
          response,
          (content) => {
            setMonthlyReportContent((prev) => prev + content);
          },
          () => {
            // ストリーミング完了時の処理（必要に応じて）
          },
          (error) => {
            console.error("今月やったことリスト生成エラー:", error);
          }
        );
      })
      .catch((error) => {
        console.error(
          "今月やったことリストの取得中にエラーが発生しました:",
          error
        );
      });
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full bg-sidebarBackground dark:bg-sidebarBackground-dark border-l border-gray-200 dark:border-gray-700 overflow-hidden transition-transform duration-300 ${
        isTaskbarOpen ? "translate-x-0" : "translate-x-full"
      }`}
      style={{ width: `${taskbarWidth}px`, zIndex: 10 }}
    >
      <div className="flex flex-col h-full">
        {/* タスクバーのヘッダー */}
        <div className="flex items-center justify-start p-2 border-b border-gray-200 dark:border-gray-700">
          <button
            onClick={() => setIsTaskbarOpen(false)}
            className="text-textSecondary dark:text-textSecondary-dark hover:text-textPrimary dark:hover:text-textPrimary-dark mr-2"
          >
            <CheckCircleIcon className="h-6 w-6" />
          </button>
          <h2 className="text-lg font-bold text-textPrimary dark:text-textPrimary-dark">
            やることリスト
          </h2>
        </div>
        {/* フィルター */}
        <div className="p-2">
          <div className="relative flex items-center bg-gray-200 dark:bg-gray-700 rounded-full">
            {/* スライドするインジケーター */}
            <div
              className="absolute top-0 h-full w-1/3 bg-white dark:bg-[#212121] rounded-full transition-all duration-300"
              style={{
                left:
                  filter === "all"
                    ? "0%"
                    : filter === "incomplete"
                    ? "33.3333%"
                    : "66.6666%",
              }}
            />
            {/* フィルターボタン */}
            <button
              onClick={() => setFilter("all")}
              className={`flex-1 py-1 rounded-full text-sm z-10 ${
                filter === "all"
                  ? "text-textPrimary dark:text-textPrimary-dark font-semibold"
                  : "text-textSecondary dark:text-textSecondary-dark"
              }`}
            >
              全て
            </button>
            <button
              onClick={() => setFilter("incomplete")}
              className={`flex-1 py-1 rounded-full text-sm z-10 ${
                filter === "incomplete"
                  ? "text-textPrimary dark:text-textPrimary-dark font-semibold"
                  : "text-textSecondary dark:text-textSecondary-dark"
              }`}
            >
              未完了
            </button>
            <button
              onClick={() => setFilter("completed")}
              className={`flex-1 py-1 rounded-full text-sm z-10 ${
                filter === "completed"
                  ? "text-textPrimary dark:text-textPrimary-dark font-semibold"
                  : "text-textSecondary dark:text-textSecondary-dark"
              }`}
            >
              完了
            </button>
          </div>
        </div>
        {/* タスクリストと新しいタスクの入力フィールドをスクロール可能に */}
        <div className="flex-1 flex flex-col overflow-y-auto">
          <div className="p-2">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              measuring={{
                droppable: {
                  strategy: MeasuringStrategy.Always,
                },
              }}
            >
              <SortableContext
                items={filteredTasks.map((task) => task._id)}
                strategy={verticalListSortingStrategy}
              >
                {filteredTasks.map((task) => (
                  <SortableItem
                    key={task._id}
                    id={task._id}
                    task={task}
                    selectedTaskId={selectedTaskId}
                    setSelectedTaskId={setSelectedTaskId}
                    updateTaskCompletion={updateTaskCompletion}
                    openTaskModal={openTaskModal}
                    deleteTask={deleteTask}
                    generatePrompt={generatePrompt}
                    updateTaskTitle={updateTaskTitle}
                  />
                ))}
              </SortableContext>

              {/* DragOverlayを追加 */}
              <DragOverlay>
                {activeId ? (
                  <SortableItem
                    id={activeId}
                    task={tasks.find((task) => task._id === activeId)}
                    dragging // draggingプロップを追加
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
            {/* 新しいタスクの入力フィールド */}
            <div
              className={`flex items-start p-2 rounded-lg hover:bg-[#ececec] dark:hover:bg-[#212121] text-textPrimary dark:text-textPrimary-dark`}
            >
              {/* 丸ボタンをタスクと同じ位置に配置 */}
              <label className="flex items-center mx-1 mt-0.5">
                <input
                  type="checkbox"
                  checked={false}
                  onChange={() => {}}
                  className="hidden"
                  onClick={(e) => e.stopPropagation()}
                />
                <span
                  className={`h-4 w-4 flex items-center justify-center border-2 rounded-full ${
                    newTaskTitle.trim() !== ""
                      ? "border-gray-700 dark:border-gray-600"
                      : "border-gray-300 dark:border-gray-400"
                  }`}
                ></span>
              </label>
              {/* 入力フィールド */}
              <input
                type="text"
                placeholder="New Task"
                value={newTaskTitle}
                onChange={(e) => setNewTaskTitle(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && newTaskTitle.trim() !== "") {
                    // 新しいタスクを保存
                    const taskData = {
                      title: newTaskTitle.trim(),
                      description: "",
                      dueDate: null,
                      priority: "normal",
                    };
                    axios
                      .post("/tasks", taskData)
                      .then((res) => {
                        setTasks([...tasks, res.data]);
                        setNewTaskTitle("");
                      })
                      .catch((err) => {
                        console.error(
                          "タスクの追加中にエラーが発生しました:",
                          err
                        );
                      });
                  }
                }}
                className="flex-1 bg-transparent text-textPrimary dark:text-textPrimary-dark outline-none"
              />
            </div>
          </div>
        </div>
        {/* 「やったことリスト」ボタンとサブメニュー */}
        <div className="bg-sidebarBackground dark:bg-sidebarBackground-dark">
          {/* アニメーションを追加 */}
          <Transition
            show={isDidListOpen}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            as={Fragment}
          >
            <div className="p-2" ref={didListRef}>
              <button
                onClick={generateDailyReport}
                className="w-full flex items-center bg-[#ececec] dark:bg-[#212121] text-textPrimary dark:text-textPrimary-dark py-2 px-4 rounded mb-2"
              >
                <Calendar1 className="h-5 w-5 mr-2" />
                今日やったこと
              </button>
              {/* 今週やったこと */}
              <button
                onClick={user && user.isPremium ? generateWeeklyReport : null}
                className={`w-full flex items-center bg-[#ececec] dark:bg-[#212121] py-2 px-4 rounded mb-2 ${
                  user && user.isPremium
                    ? "text-textPrimary dark:text-textPrimary-dark"
                    : "text-gray-500 dark:text-gray-400 cursor-not-allowed"
                }`}
                disabled={!user || !user.isPremium}
              >
                <CalendarRange className="h-5 w-5 mr-2" />
                今週やったこと
                {(!user || !user.isPremium) && (
                  <LockClosedIcon className="h-4 w-4 ml-2 text-gray-500" />
                )}
              </button>
              {/* 今月やったこと */}
              <button
                onClick={user && user.isPremium ? generateMonthlyReport : null}
                className={`w-full flex items-center bg-[#ececec] dark:bg-[#212121] py-2 px-4 rounded ${
                  user && user.isPremium
                    ? "text-textPrimary dark:text-textPrimary-dark"
                    : "text-gray-500 dark:text-gray-400 cursor-not-allowed"
                }`}
                disabled={!user || !user.isPremium}
              >
                <CalendarDays className="h-5 w-5 mr-2" />
                今月やったこと
                {(!user || !user.isPremium) && (
                  <LockClosedIcon className="h-4 w-4 ml-2 text-gray-500" />
                )}
              </button>
            </div>
          </Transition>
          <div className="p-2">
            <button
              id="didListToggleButton"
              onClick={() => setIsDidListOpen(!isDidListOpen)}
              className="w-full flex items-center bg-[#ececec] dark:bg-[#212121] text-textPrimary dark:text-textPrimary-dark py-2 px-4 rounded"
            >
              <FileCheck className="h-5 w-5 mr-2" />
              やったことリストを作成
            </button>
          </div>
        </div>
      </div>
      {/* プロンプト生成モーダル */}
      <Transition appear show={isPromptModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsPromptModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95 -translate-y-4"
            enterTo="opacity-100 scale-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100 translate-y-0"
            leaveTo="opacity-0 scale-95 -translate-y-4"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 -translate-y-4"
                enterTo="opacity-100 scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 translate-y-0"
                leaveTo="opacity-0 scale-95 -translate-y-4"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-surface dark:bg-surface-dark shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-textPrimary dark:text-textPrimary-dark"
                  >
                    タスク解決のためのプロンプト
                  </Dialog.Title>
                  <div className="mt-4">
                    {isGeneratingPrompt ? (
                      <p className="text-textSecondary dark:text-textSecondary-dark">
                        プロンプトを生成しています...
                      </p>
                    ) : (
                      <div className="whitespace-pre-wrap text-textPrimary dark:text-textPrimary-dark">
                        {promptContent}
                      </div>
                    )}
                  </div>

                  <div className="mt-6 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-textPrimary dark:text-textPrimary-dark bg-gray-200 dark:bg-gray-700 border border-transparent rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-2"
                      onClick={() => setIsPromptModalOpen(false)}
                    >
                      閉じる
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* 今日やったことリストモーダル */}
      <Transition appear show={isDailyReportModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsDailyReportModalOpen(false)}
        >
          {/* モーダルの背景 */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95 -translate-y-4"
            enterTo="opacity-100 scale-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100 translate-y-0"
            leaveTo="opacity-0 scale-95 -translate-y-4"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/* モーダルの内容 */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 -translate-y-4"
                enterTo="opacity-100 scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 translate-y-0"
                leaveTo="opacity-0 scale-95 -translate-y-4"
              >
                <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-surface dark:bg-surface-dark shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-textPrimary dark:text-textPrimary-dark"
                  >
                    今日やったことリスト
                  </Dialog.Title>
                  <div className="mt-4">
                    {/* ここを修正します */}
                    <div className="prose max-w-full dark:prose-invert overflow-x-auto">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          code({
                            node,
                            inline,
                            className,
                            children,
                            ...props
                          }) {
                            const hasLang = /language-(\w+)/.exec(
                              className || ""
                            );
                            if (inline) {
                              return (
                                <code
                                  className={className}
                                  style={{ fontSize: "1rem" }}
                                  {...props}
                                >
                                  {children}
                                </code>
                              );
                            } else if (hasLang) {
                              return (
                                <CodeBlock
                                  language={hasLang[1]}
                                  value={String(children).replace(/\n$/, "")}
                                />
                              );
                            } else {
                              return (
                                <code
                                  className={className}
                                  style={{ fontSize: "1rem" }}
                                  {...props}
                                >
                                  {children}
                                </code>
                              );
                            }
                          },
                        }}
                      >
                        {dailyReportContent ||
                          "今日やったことリストを生成しています..."}
                      </ReactMarkdown>
                    </div>
                    {/* ここまで */}
                  </div>

                  <div className="mt-6 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-textPrimary dark:text-textPrimary-dark bg-gray-200 dark:bg-gray-700 border border-transparent rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-2"
                      onClick={() => setIsDailyReportModalOpen(false)}
                    >
                      閉じる
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* 今週やったことリストモーダル */}
      <Transition appear show={isWeeklyReportModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsWeeklyReportModalOpen(false)}
        >
          {/* モーダルの背景 */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95 -translate-y-4"
            enterTo="opacity-100 scale-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100 translate-y-0"
            leaveTo="opacity-0 scale-95 -translate-y-4"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/* モーダルの内容 */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 -translate-y-4"
                enterTo="opacity-100 scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 translate-y-0"
                leaveTo="opacity-0 scale-95 -translate-y-4"
              >
                <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-surface dark:bg-surface-dark shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-textPrimary dark:text-textPrimary-dark"
                  >
                    今週やったことリスト
                  </Dialog.Title>
                  <div className="mt-4">
                    {/* 修正部分開始 */}
                    <div className="prose max-w-full dark:prose-invert overflow-x-auto">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          code({
                            node,
                            inline,
                            className,
                            children,
                            ...props
                          }) {
                            const hasLang = /language-(\w+)/.exec(
                              className || ""
                            );
                            if (inline) {
                              return (
                                <code
                                  className={className}
                                  style={{ fontSize: "1rem" }}
                                  {...props}
                                >
                                  {children}
                                </code>
                              );
                            } else if (hasLang) {
                              return (
                                <CodeBlock
                                  language={hasLang[1]}
                                  value={String(children).replace(/\n$/, "")}
                                />
                              );
                            } else {
                              return (
                                <code
                                  className={className}
                                  style={{ fontSize: "1rem" }}
                                  {...props}
                                >
                                  {children}
                                </code>
                              );
                            }
                          },
                        }}
                      >
                        {weeklyReportContent ||
                          "今週やったことリストを生成しています..."}
                      </ReactMarkdown>
                    </div>
                    {/* 修正部分終了 */}
                  </div>

                  <div className="mt-6 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-textPrimary dark:text-textPrimary-dark bg-gray-200 dark:bg-gray-700 border border-transparent rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-2"
                      onClick={() => setIsWeeklyReportModalOpen(false)}
                    >
                      閉じる
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* 今月やったことリストモーダル */}
      <Transition appear show={isMonthlyReportModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsMonthlyReportModalOpen(false)}
        >
          {/* モーダルの背景 */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95 -translate-y-4"
            enterTo="opacity-100 scale-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100 translate-y-0"
            leaveTo="opacity-0 scale-95 -translate-y-4"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/* モーダルの内容 */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 -translate-y-4"
                enterTo="opacity-100 scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 translate-y-0"
                leaveTo="opacity-0 scale-95 -translate-y-4"
              >
                <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-surface dark:bg-surface-dark shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-textPrimary dark:text-textPrimary-dark"
                  >
                    今月やったことリスト
                  </Dialog.Title>
                  <div className="mt-4">
                    {/* 修正部分開始 */}
                    <div className="prose max-w-full dark:prose-invert overflow-x-auto">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          code({
                            node,
                            inline,
                            className,
                            children,
                            ...props
                          }) {
                            const hasLang = /language-(\w+)/.exec(
                              className || ""
                            );
                            if (inline) {
                              return (
                                <code
                                  className={className}
                                  style={{ fontSize: "1rem" }}
                                  {...props}
                                >
                                  {children}
                                </code>
                              );
                            } else if (hasLang) {
                              return (
                                <CodeBlock
                                  language={hasLang[1]}
                                  value={String(children).replace(/\n$/, "")}
                                />
                              );
                            } else {
                              return (
                                <code
                                  className={className}
                                  style={{ fontSize: "1rem" }}
                                  {...props}
                                >
                                  {children}
                                </code>
                              );
                            }
                          },
                        }}
                      >
                        {monthlyReportContent ||
                          "今月やったことリストを生成しています..."}
                      </ReactMarkdown>
                    </div>
                    {/* 修正部分終了 */}
                  </div>

                  <div className="mt-6 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-textPrimary dark:text-textPrimary-dark bg-gray-200 dark:bg-gray-700 border border-transparent rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 mr-2"
                      onClick={() => setIsMonthlyReportModalOpen(false)}
                    >
                      閉じる
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* タスク追加・編集モーダル */}
      <Transition
        appear
        show={isTaskModalOpen}
        as={Fragment}
        afterLeave={() => {
          setEditingTask(null);
          setTaskFormData({
            title: "",
            description: "",
            dueDate: null,
            priority: "normal",
            labels: [],
          });
        }}
      >
        <Dialog as="div" className="relative z-50" onClose={closeTaskModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95 -translate-y-4"
            enterTo="opacity-100 scale-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100 translate-y-0"
            leaveTo="opacity-0 scale-95 -translate-y-4"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95 -translate-y-4"
                enterTo="opacity-100 scale-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 translate-y-0"
                leaveTo="opacity-0 scale-95 -translate-y-4"
              >
                <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-surface dark:bg-surface-dark shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-textPrimary dark:text-textPrimary-dark"
                  >
                    {editingTask ? "タスクを編集" : "新しいタスクを追加"}
                  </Dialog.Title>
                  <div className="mt-4">
                    <label className="block mb-2">
                      <span className="text-textPrimary dark:text-textPrimary-dark">
                        タイトル
                      </span>
                      <input
                        type="text"
                        name="title"
                        value={taskFormData.title}
                        onChange={handleTaskFormChange}
                        className="w-full p-2 border rounded mt-1 bg-surface dark:bg-surface-dark text-textPrimary dark:text-textPrimary-dark"
                        placeholder="タスクのタイトル"
                      />
                    </label>
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-textPrimary dark:text-textPrimary-dark">
                        説明
                      </label>
                      <TaskEditor
                        content={taskFormData.description}
                        onChange={(html) => {
                          setTaskFormData({
                            ...taskFormData,
                            description: html,
                          });
                        }}
                      />
                    </div>
                    <label className="block mb-2">
                      <span className="text-textPrimary dark:text-textPrimary-dark">
                        締め切り日
                      </span>
                      <DatePicker
                        selected={taskFormData.dueDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy/MM/dd"
                        className="w-full p-2 border rounded mt-1 bg-surface dark:bg-surface-dark text-textPrimary dark:text-textPrimary-dark"
                        placeholderText="締め切り日を選択 (任意)"
                      />
                    </label>
                    <label className="block mb-2">
                      <span className="text-textPrimary dark:text-textPrimary-dark">
                        優先度
                      </span>
                      <select
                        name="priority"
                        value={taskFormData.priority}
                        onChange={handleTaskFormChange}
                        className="w-full p-2 border rounded mt-1 bg-surface dark:bg-surface-dark text-textPrimary dark:text-textPrimary-dark"
                      >
                        <option value="high">高</option>
                        <option value="normal">中</option>
                        <option value="low">低</option>
                      </select>
                    </label>
                    <label className="block mb-2">
                      <span className="text-textPrimary dark:text-textPrimary-dark">
                        ラベル
                      </span>
                      <input
                        type="text"
                        name="labels"
                        value={
                          taskFormData.labels
                            ? taskFormData.labels.join(", ")
                            : ""
                        }
                        onChange={(e) =>
                          setTaskFormData({
                            ...taskFormData,
                            labels: e.target.value
                              .split(",")
                              .map((label) => label.trim()),
                          })
                        }
                        className="w-full p-2 border rounded mt-1 bg-surface dark:bg-surface-dark text-textPrimary dark:text-textPrimary-dark"
                        placeholder="ラベルをカンマで区切って入力"
                      />
                    </label>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default TaskList;
