// src/pages/VerifyEmail.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";

function VerifyEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [verificationCode, setVerificationCode] = useState("");
  const email = location.state?.email || "";
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // 追加
  const [isResending, setIsResending] = useState(false); // 追加
  const [resendTimer, setResendTimer] = useState(0); // 追加

  // 再送信ボタンのタイマーを設定
  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  // クライアントサイドのバリデーション関数
  const validateVerificationCode = (code) => {
    // 認証コードが6桁の数字であることを確認
    const re = /^\d{6}$/;
    return re.test(code);
  };

  const handleVerify = async (e) => {
    e.preventDefault();

    // クライアントサイドのバリデーション
    if (!validateVerificationCode(verificationCode)) {
      setError("6桁の有効な認証コードを入力してください。");
      return;
    }

    try {
      setIsSubmitting(true);
      setError("");
      setMessage("");

      const response = await axios.post("/verify-email", {
        email,
        verificationCode,
      });

      setMessage(response.data.message || "メールアドレスが確認されました。");

      // 認証が成功したら、ログインページにリダイレクト
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error("Error verifying email:", error);
      setError(
        error.response?.data?.error ||
          "メール認証に失敗しました。もう一度お試しください。"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setIsResending(true);
      setError("");
      setMessage("");

      const response = await axios.post("/resend-verification-code", {
        email,
      });

      setMessage(
        response.data.message ||
          "認証コードを再送信しました。メールをご確認ください。"
      );

      // 再送信ボタンを一時的に無効化
      setResendTimer(60); // 60秒間
    } catch (error) {
      console.error("Error resending verification code:", error);
      setError(
        error.response?.data?.error ||
          "認証コードの再送信に失敗しました。もう一度お試しください。"
      );
    } finally {
      setIsResending(false);
    }
  };

  // メールアドレスがない場合の処理
  if (!email) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4">
        <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md text-center">
          <p className="text-red-500 mb-4 font-semibold">
            メールアドレスが見つかりません。再度登録を行ってください。
          </p>
          <Link
            to="/register"
            className="text-blue-600 dark:text-blue-400 hover:underline"
          >
            新規登録ページへ戻る
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          メールアドレスの確認
        </h2>
        {message && (
          <p className="text-green-500 mb-4 text-center font-semibold">
            {message}
          </p>
        )}
        {error && (
          <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
        )}
        <form onSubmit={handleVerify} noValidate>
          <div className="mb-4">
            <label
              htmlFor="verificationCode"
              className="block text-gray-700 dark:text-gray-300 mb-2"
            >
              認証コード
            </label>
            <input
              id="verificationCode"
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
              className={`w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary ${
                error ? "border-red-500" : "border-gray-300"
              } bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white`}
              placeholder="メールで受け取った認証コードを入力"
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full bg-primary text-white py-3 px-4 rounded-lg hover:bg-primary-dark transition-colors duration-200 font-semibold ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? "送信中..." : "認証"}
          </button>
        </form>
      </div>
      <div className="text-center mt-4">
        <p className="text-gray-600 dark:text-gray-400">
          認証コードを受け取っていない場合は
        </p>
        <button
          type="button"
          onClick={handleResendCode}
          disabled={isResending || resendTimer > 0}
          className={`text-primary hover:underline mt-2 ${
            isResending || resendTimer > 0
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
        >
          {isResending
            ? "再送信中..."
            : resendTimer > 0
            ? `再送信可能まで ${resendTimer}秒`
            : "認証コードを再送信"}
        </button>
      </div>
    </div>
  );
}

export default VerifyEmail;
