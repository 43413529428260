// Login.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { FcGoogle } from "react-icons/fc";
import { SiMicrosoft } from "react-icons/si";

function Login({ setAuthToken }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  const handleSocialLogin = (provider) => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/${provider}`;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setMessage("");

      if (!email || !password) {
        setError("メールアドレスとパスワードを入力してください。");
        return;
      }

      const response = await axios.post("/login", {
        email,
        password,
      });

      const token = response.data.token;
      localStorage.setItem("authToken", token);
      setAuthToken(token);
      localStorage.setItem("savedEmail", email);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      navigate("/chat");
    } catch (error) {
      console.error("Error logging in:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.error === "メールアドレスが確認されていません。"
      ) {
        setMessage(
          "メールアドレスが確認されていません。認証コードを入力してください。"
        );

        setTimeout(() => {
          navigate("/verify-email", { state: { email } });
        }, 2000);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        setError(error.response.data.error);
      } else {
        setError("ログインに失敗しました。もう一度お試しください。");
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 dark:text-white">
          ログイン
        </h2>
        {message && (
          <p className="text-green-500 mb-4 text-center font-semibold">
            {message}
          </p>
        )}
        {error && (
          <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>
        )}
        <form onSubmit={handleLogin} autoComplete="on">
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              メールアドレス
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white"
              placeholder="メールアドレスを入力"
              autoComplete="username"
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700 dark:text-gray-300 mb-2">
              パスワード
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white pr-12"
                placeholder="パスワードを入力"
                autoComplete="current-password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-3 flex items-center text-gray-500 dark:text-gray-400"
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5" />
                ) : (
                  <EyeIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>
          <div className="mb-4 text-right">
            <Link
              to="/forgot-password"
              className="text-blue-600 dark:text-blue-400 hover:underline text-sm"
            >
              パスワードをお忘れですか？
            </Link>
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-3 px-4 rounded-lg hover:bg-primary-dark transition-colors duration-200 font-semibold"
          >
            ログイン
          </button>
        </form>
        <div className="mt-6 flex items-center">
          <hr className="flex-grow border-gray-300 dark:border-gray-600" />
          <span className="mx-4 text-gray-500 dark:text-gray-400">または</span>
          <hr className="flex-grow border-gray-300 dark:border-gray-600" />
        </div>
        {/* ソーシャルログインボタン */}
        <button
          type="button"
          onClick={() => handleSocialLogin("google")}
          className="w-full flex items-center justify-center bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 py-2 px-4 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-200 mt-4 border border-gray-300 dark:border-gray-600"
        >
          <FcGoogle className="h-5 w-5 mr-2" />
          Googleでログイン
        </button>
        <button
          type="button"
          onClick={() => handleSocialLogin("microsoft")}
          className="w-full flex items-center justify-center bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 py-2 px-4 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-200 mt-2 border border-gray-300 dark:border-gray-600"
        >
          <SiMicrosoft className="h-5 w-5 mr-2 text-blue-600" />
          Microsoftでログイン
        </button>
        <p className="mt-6 text-center text-gray-600 dark:text-gray-400">
          アカウントをお持ちでないですか？{" "}
          <Link
            to="/register"
            className="text-blue-600 dark:text-blue-400 hover:underline"
          >
            新規登録はこちら
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
