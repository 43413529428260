// frontend/src/components/common/SortableItem.js
import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  PencilIcon,
  TrashIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";

function SortableItem({
  id,
  task,
  selectedTaskId,
  setSelectedTaskId,
  updateTaskCompletion,
  openTaskModal,
  deleteTask,
  generatePrompt,
  updateTaskTitle,
  dragging,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(task.title);

  const saveTitle = () => {
    if (editedTitle.trim() === "" || editedTitle === task.title) {
      setEditedTitle(task.title);
      setIsEditingTitle(false);
      return;
    }

    // タイトルをサーバーに更新
    axios
      .put(`/tasks/${task._id}`, { title: editedTitle })
      .then((res) => {
        updateTaskTitle(task._id, editedTitle);
        setIsEditingTitle(false);
      })
      .catch((err) => {
        console.error("タスクのタイトル更新に失敗しました:", err);
      });
  };

  // 締切日をフォーマットする関数
  const formatDueDate = (date) => {
    if (!date) {
      return { formattedDate: "", isOverdue: false };
    }
    const dueDate = new Date(date);
    const now = new Date();
    dueDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
    const isOverdue = dueDate < now;
    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = dueDate.toLocaleDateString(undefined, options);
    return { formattedDate, isOverdue };
  };

  const { formattedDate, isOverdue } = formatDueDate(task.dueDate);

  return (
    <div
      ref={setNodeRef}
      style={{ ...style }}
      {...(!dragging ? { ...attributes, ...listeners } : {})}
      className={`flex items-start p-2 rounded-lg cursor-pointer hover:bg-[#ececec] dark:hover:bg-[#212121] group ${
        task._id === selectedTaskId
          ? "bg-[#ececec] text-black dark:bg-[#212121] dark:text-white"
          : "text-textPrimary dark:text-textPrimary-dark"
      }`}
      onClick={() => {
        if (!isEditingTitle) {
          openTaskModal(task);
        }
      }}
    >
      {/* カスタムチェックボックス */}
      <label
        className="flex items-center mx-1 mt-0.5 flex-shrink-0"
        onClick={(e) => e.stopPropagation()}
      >
        <input
          type="checkbox"
          checked={task.completed}
          onChange={() => updateTaskCompletion(task._id, !task.completed)}
          className="hidden"
        />
        <span
          className={`h-4 w-4 flex items-center justify-center border-2 rounded-full ${
            task.completed
              ? "bg-gray-700 border-gray-700 dark:bg-gray-600 dark:border-gray-600"
              : "border-gray-500 dark:border-gray-400"
          }`}
        >
          {task.completed && (
            <svg
              className="h-2 w-2 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M5 13l4 4L19 7" />
            </svg>
          )}
        </span>
      </label>
      {/* タスク内容 */}
      <div className="flex-1 min-w-0">
        <div className="flex justify-between items-start group">
          <div className="flex-1 min-w-0">
            {isEditingTitle ? (
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={saveTitle}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    saveTitle();
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                className="w-full bg-transparent outline-none border-b border-gray-400"
                autoFocus
              />
            ) : (
              <span
                className={`break-words ${
                  task.completed
                    ? "line-through text-gray-500"
                    : "text-textPrimary dark:text-textPrimary-dark"
                }`}
              >
                {task.title}
              </span>
            )}
          </div>
          {/* ボタン */}
          <div className="flex items-center ml-2 space-x-1 flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsEditingTitle(true);
                setEditedTitle(task.title);
              }}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <PencilIcon className="h-4 w-4" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                deleteTask(task._id);
              }}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <TrashIcon className="h-4 w-4" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                generatePrompt(task);
              }}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <LightBulbIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
        {/* 締切日を表示 */}
        {formattedDate && (
          <div className="mt-1">
            <span
              className={`inline-block px-2 py-1 text-xs font-medium rounded ${
                isOverdue
                  ? "bg-red-200 dark:bg-red-700 text-red-700 dark:text-red-300"
                  : "bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
              }`}
            >
              {isOverdue ? "期限切れ: " : "締切: "} {formattedDate}
            </span>
          </div>
        )}
        {/* ラベルの表示 */}
        {task.labels && task.labels.length > 0 && (
          <div className="mt-1">
            {task.labels.map((label, index) => (
              <span
                key={index}
                className="inline-block px-2 py-1 mr-1 text-xs font-medium rounded bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
              >
                {label}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default SortableItem;
