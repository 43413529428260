// SubscriptionSuccess.js
import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../AppContext";

function SubscriptionSuccess() {
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    axios
      .get("/user")
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [setUser]);

  return (
    <div>
      <h1>ご購入ありがとうございます！</h1>
      <p>サブスクリプションが成功しました。</p>
    </div>
  );
}

export default SubscriptionSuccess;
